// Customizable Area Start
import React from 'react';
import { styled } from "@material-ui/core";
import { Link } from "react-router-dom";
import { email, contact, facebook, linkedin, twitter, instagram } from "./assets/index";

interface IFunctionComponentProps {
  socialMediaLinks: {
    id: string;
    type: string;
    attributes: {
      fb_link: string;
      linkedin_link: string;
      instagram_link: string;
      twitter_link: string;
    };
  };
  contactDetails: {
    id: string;
    type: string;
    attributes: {
      email: string;
      full_phone_number: string;
    }
  }
}

const Footer: React.FC<IFunctionComponentProps> = ({ socialMediaLinks, contactDetails }) => {
  const isMobileView = window.matchMedia("(max-width: 550px)").matches;
  return (
    <SiteFooter>
      <div className={( !isMobileView && window.location.pathname !== "/StripePaymentView" && window.location.pathname !== "/Contactus")?"show-footer":"hide-footer"}>
      {!isMobileView ?
        <Container className={( !isMobileView && window.location.pathname !== "/StripePaymentView" && window.location.pathname !== "/Contactus")?"show-container":"hide-container"}>
          {window.location.pathname !== "/StripePaymentView" && window.location.pathname !== "/Contactus" &&
          <FooterWrapper>
            <FooterList>
                <NavLink to="/AboutUs">ABOUT US</NavLink>
              <FooterSubList className="footer-links">
                <Link to="/career">Careers</Link>
                <Link to="/TermsConditions">TERMS  &  CONDITIONS</Link>
                <Link to="/PrivacyPolicy">PRIVACY POLICY</Link>
                <A href='/#giftRegistry'>Design service</A>
                <A href='/#giftRegistry'>special gift registry</A>
                <Link to="/interior-designers">Designer list</Link>
              </FooterSubList>
            </FooterList>
            {contactDetails && <FooterList>
              <NavLink to="/Contactus">CONTACT US</NavLink>
              <FooterSubList><ContactImg src={email} /> <A href='mailto:admin123@gmail.com'>{contactDetails.attributes.email}</A></FooterSubList>
              <FooterSubList><ContactImg src={contact} /> <A href='tel:17XXXXXXXXXX'>{contactDetails.attributes.full_phone_number}</A></FooterSubList>
            </FooterList>}
            <FooterList>
              <H3>SOCIAL  MEDIA</H3>
              {socialMediaLinks &&
                <FooterSubList style={{ gap: "20px" }}>
                  <A href={`${socialMediaLinks.attributes.fb_link}`} target='_blank'><img src={facebook} /></A>
                  <A href={`${socialMediaLinks.attributes.linkedin_link}`} target='_blank'><img src={linkedin} /></A>
                  <A href={`${socialMediaLinks.attributes.twitter_link}`} target='_blank'><img src={twitter} /></A>
                  <A href={`${socialMediaLinks.attributes.instagram_link}`} target='_blank'><img src={instagram} /></A>
                </FooterSubList>
              }
            </FooterList>
          </FooterWrapper>
          }
        </Container> :
                <div style={{ textAlign: "center" }}>
          <MobileLink to="/aboutUs">{"ABOUT US"}</MobileLink>
          <MobileLink to="/career">{"CAREERS"}</MobileLink>
          <MobileLink to="/Contactus">{"CONTACT US"}</MobileLink>
          <MobileLink to="/TermsConditions">{"TERMS  &  CONDITIONS"}</MobileLink>
          <MobileLink to="/PrivacyPolicy">{"PRIVACY POLICY"}</MobileLink>
          <MobileLink to="/#giftRegistry">{"DESIGN SERVICE"}</MobileLink>
          <MobileLink to="/#giftRegistry">{"SPECIAL GIFT REGISTRY"}</MobileLink>
          <MobileLink to="/interior-designers">{"DESIGNER LIST"}</MobileLink>
          {contactDetails &&
            <div className='contactUsSection'>
              <A href='mailto:admin123@gmail.com'>{contactDetails.attributes.email}</A>
              <div className='verticalDivider' ></div>
              <A href='tel:17XXXXXXXXXX'>{contactDetails.attributes.full_phone_number}</A>
            </div>
          }
          <FooterList>
            {socialMediaLinks &&
              <FooterSubList style={{ gap: "20px" }}>
                <A href={`${socialMediaLinks.attributes.instagram_link}`} target='_blank'><img src={instagram} /></A>
                <A href={`${socialMediaLinks.attributes.linkedin_link}`} target='_blank'><img src={linkedin} /></A>
                <A href={`${socialMediaLinks.attributes.fb_link}`} target='_blank'><img src={facebook} /></A>
                <A href={`${socialMediaLinks.attributes.twitter_link}`} target='_blank'><img src={twitter} /></A>
              </FooterSubList>
            }
          </FooterList>
        </div>
      }
      </div>
    </SiteFooter>
  );
};

export default Footer;

const SiteFooter = styled("footer")({
  "& .show-footer":{
    height: "auto",
    background: "#FFF",
    padding: "100px 0 0",
    borderTop:  "1px solid #25272933",
  "@media (max-width: 550px)": {
    padding: "20px",
    "& .contactUsSection": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      margin: "20px",
      fontFamily: "Raleway",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "16.44px",
      color: "#252729CC",
    },
    "& .contactUsSection .verticalDivider": {
      height: "20px",
      margin: "0px 10px",
      width: "1px",
      background: "#000"
    },
  }
  },
  "& .hide-footer": {
    background: "#FFF",
    padding: 0,
    borderTop: "0px",
    "@media (max-width: 550px)": {
      borderTop:"1px solid #25272933",
      padding: "20px",
      "& .contactUsSection .verticalDivider": {
        height: "20px",
        margin: "0px 10px",
        width: "1px",
        background: "#000",
        display: "none"
      },
      "& .contactUsSection": {
        display: "flex",
        alignItems: "center",
        lineHeight: "16.44px",
        fontFamily: "Raleway",
        fontSize: "16px",
        justifyContent: "center",
        margin: "20px",
        fontWeight: 400,
        color: "#252729CC",
        flexDirection: "column",
        gap: "5px"
      }
    },
  }
});
const Container = styled("div")({
  "& .show-container":{
    width: "100%",
    maxWidth: "1730px",
    margin: "0 auto",
    minHeight: 300
  },
  "& .hide-container":{
    maxWidth: "1730px",
    width: "100%"
  },
  
});
const FooterWrapper = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-evenly",
});
const FooterList = styled("div")({
  display: "flex",
  flexDirection: "column",
  "& a": {
    color: "#252729",
  },
  "& .footer-links": {
    flexDirection: "column",
    alignItems: "start",
    textTransform: "uppercase",
    fontSize: "16px"
  },
  "& .footer-links a": {
    padding: "5px 0"
  }
});
const H3 = styled("h3")({
  color: "#252729",
  fontSize: "22px",
  fontFamily: "Raleway",
  fontWeight: 500,
  margin: "0 0 40px 0",
});
const FooterSubList = styled("div")({
  display: "flex",
  alignItems: "center",
  margin: "0 0 20px 0px",
  fontSize: "16px",
  "@media (max-width: 550px)": {
    justifyContent: "center"
  }
});
const A = styled("a")({
  color: "var(--shark)",
});
const ContactImg = styled("img")({
  marginRight: "20px",
});
const NavLink = styled(Link)({
  marginBottom: "40px",
  fontSize: "22px",
  fontFamily: "Raleway",
  fontWeight: 500,
  color: "#252729",
})
const MobileLink = styled(Link)({
  "@media (max-width: 550px)": {
    margin: "15px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "0px",
    color: "#252729",
    display: 'inline-block'
  }
})
// Customizable Area End
