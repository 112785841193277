// Customizable Area Start
import React from "react";
import { styled, Box, Typography } from "@material-ui/core";
import { notFoundImage } from "./assets";

interface INotFoundDataProps {
  message: string;
}

const NotFoundData: React.FC<INotFoundDataProps> = ({ message }) => {
  return (
    <NotFoundDataContainer>
      <img src={notFoundImage} alt="not found" className="not-found-image" />
      <Typography className="not-found-text">{message}</Typography>
    </NotFoundDataContainer>
  );
};

const NotFoundDataContainer = styled(Box)(({ theme }) => ({
  height: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  marginBottom: "20px",
  "& .not-found-text": {
    color: "#2527291A",
    fontFamily: "Raleway",
    fontSize: "65px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "normal",
    width:"100%",
    textAlign:"Center"

  },
  "& .not-found-image": {
    height: "300px",
    width: "300px",
  },
  [theme.breakpoints.down("md")]: {
    height: "320px",
    "& .not-found-text": {
      fontSize: "23px",
    },
    "& .not-found-image": {
      height: "270px",
      width: "270px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .not-found-text": {
      fontSize: "30px",
    },
    "& .not-found-image": {
      height: "240px",
      width: "240px",
    },
    height: "270px",
  },
  [theme.breakpoints.down("xs")]: {
    height: "240px",
    "& .not-found-text": {
      fontSize: "18px",
    },
    "& .not-found-image": {
      height: "200px",
      width: "200px",
    },
  },
}));

export default NotFoundData;

// Customizable Area End
