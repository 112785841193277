import React from "react";
// Customizable Area Start
import { Box, Typography, createStyles, withStyles, Popover, styled } from "@material-ui/core";
import { message, cross } from "./assets"
import ContactusViewController, { Props } from "./ContactusViewController";
import { ToastContainer } from "react-toastify";
export const configJSON = require("./config");

// Customizable Area End


class ContactusView extends ContactusViewController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    renderPopup = () => {
        const styles = this.props.classes;
        return (
            <Box className={styles.modalView}>
                <Box className={styles.contactusTextBox}>
                    <Typography className={styles.contactusTextStyle}>Contact Us</Typography>
                    <img src={cross} alt="cross-img" className={styles.crossImg} onClick={this.handlePopoverClose} />
                </Box>
                <form className={styles.formInput} onSubmit={this.handleValidation} data-test-id="sendBtntestID">
                    <Box className={styles.TextInputField}>
                        <Typography className={styles.nameText}>Name</Typography>
                        <input 
                            className={this.state.isName ? styles.errorInputStyle : styles.inputStyle}
                            type="text"
                            value={this.state.name}
                            onChange={(e) => this.onChangeName(e.target.value)}
                            data-test-id="nameInputID"
                        />
                        {this.state.isName && <Typography className={styles.errormsg}>{configJSON.namePlaceHolder}</Typography>}
                    </Box>
                    <Box className={styles.TextInputField}>
                        <Typography className={styles.nameText}>Email</Typography>
                        <input className={this.state.isEmail ? styles.errorInputStyle : styles.inputStyle}
                            value={this.state.email}
                            onChange={(e) => this.onChangeEmail(e.target.value)}
                            data-test-id="emailInputID"
                        />
                        {this.state.isEmail && <Typography className={styles.errormsg}>{configJSON.emailPlaceHolder}</Typography>}
                    </Box>
                    <Box className={styles.TextInputField}>
                        <Typography className={styles.nameText}>Phone Number</Typography>
                        <input
                            type="text"
                            className={ this.phoneValidation() ? styles.errorInputStyle : styles.inputStyle}
                            value={this.state.phoneNumber}
                            onChange={(e) => this.onChangePhone(e.target.value)}
                            data-test-id="numberInputID"
                        />
                        {this.state.isPhoneNumber && <Typography className={styles.errormsg}>{configJSON.numberPlaceHolder}</Typography>}
                        {this.state.isInvalidPhoneNumber && <Typography data-test-id="errorMessageTestId" className={styles.errormsg}>{configJSON.numberPlaceHolder}</Typography>}                        
                    </Box>
                    <Box className={styles.TextInputField}>
                        <Typography className={styles.nameText}>Message</Typography>
                        <div className={ this.state.isMessage ? styles.errorMessageStyle : styles.messageInputStyle}>
                        <textarea className={styles.msgInputStyle}
                            placeholder="Type here..."
                            value={this.state.message}
                            onChange={(e) => this.onChangeMessage(e.target.value)}
                            data-test-id="messageInputID"
                            />
                        </div>
                        {this.state.isMessage && <Typography className={styles.errormsg}>{configJSON.commentsPlaceHolder}</Typography>}
                    </Box>
                    <CustomButton type="submit">Send</CustomButton>
                </form>
            </Box>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        const styles = this.props.classes;
        return (
            <>
                <div className={styles.contactViewBox} onClick={this.handlePopoverOpen}>
                    <img src={message} alt="message" className={styles.messageImg} />
                </div>
                <Popover
                    id="popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handlePopoverClose}
                    style={webStyle.popup}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    classes={{ paper: styles.popoverPaper }}
                >
                    <div>
                        {this.renderPopup()}
                    </div>
                    <ToastContainer
                    style={{zIndex:9999}}
                    position="top-right"
                    autoClose={3000}
                />
                </Popover>

                {this.state.backOverlayOpen ?
                    <div className={styles.popOverOverlayStyles}>
                    </div> : ''
                }
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const webStyle = {
    popup: {
        left: "-2%",
        top: "-1.25%"
        }
};

const CustomButton = styled("button")({
    border: 0,
    background: "var(--driftwood)",
    color: "var(--white)",
    padding: "14px 0",
    fontFamily: "Raleway",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "28px",
    borderRadius: "50px",
    cursor: "pointer"
});
const styles = createStyles({
    contactViewBox: {
        position: "fixed",
        width: "50px",
        height: "50px",
        borderRadius: "64px",
        background: "#FFF",
        boxShadow: "0px 4px 20px 0px rgba(143, 143, 143, 0.50)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bottom: "25px",
        right: "25px",
        zIndex: 9999,
        cursor: 'pointer',
        "@media(max-width:520px)": {
            width: '40px',
            height: '40px'
        },
    },
    messageImg: {
        width: "25px",
        height: "25px",
        "@media(max-width:520px)": {
            width: '20px',
            height: '20px'
        },
    },
    modalView: {
        width: "469px",
        height: "auto",
        borderRadius: "8px",
        background: "#FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        padding: "25px 29px",
        display: "flex",
        flexDirection: "column",
        "@media(max-width:480px)": {
            width: "90vw",
        },
        "@media(max-width:650px)": {
            width: "90vw",
        }

    },
    popoverPaper: {
        "&::-webkit-scrollbar": {
            display: "none",
        },
        borderRadius: "8px",
        "scrollbar-width": "none",
        "-ms-overflow-style": "none",
        "@media(max-width:650px)": {
            width: "90vw",
            left: '10vw'
        }
    },
    crossImg: {
        width: "29px",
        height: "29px",
        cursor: "pointer",
    },
    contactusTextBox: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        paddingBottom: "30px"
    },
    contactusTextStyle: {
        color: "#25272999",
        fontFamily: "Raleway",
        fontSize: "22px",
        fontWeight: 600,
        "@media(max-width:520px)": {
            fontSize: '20px'
        },

    },
    TextInputField: {
        gap: "5px",
        display: "flex",
        flexDirection: "column"
    },
    nameText: {
        color: "#25272999",
        fontFamily: "Raleway",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: '21.13px',
        "@media(max-width:480px)": {
            fontSize: '14px',
            fontWeight: 500
        },
    },
    formInput: {
        gap: "16px",
        display: "flex",
        flexDirection: "column"
    },
    inputStyle: {
        width: "100%",
        height: "50px",
        border: "none",
        outline: "none",
        borderRadius: "4px",
        backgroundColor: "rgba(243, 243, 243, 0.5)",
        padding: "10px",
        fontFamily: 'Raleway',
        fontWeight: 400,
        color: "#25272999",
        fontSize: "16px",
        lineHeight: "14.09px"
    },
    errorInputStyle: {
        width: "100%",
        border: "2px solid red",
        borderRadius: "4px",
        color: "#25272999",
        fontSize: "16px",
        fontFamily: "Raleway",
        lineHeight: "14.09px",
        fontWeight: 400,
        backgroundColor: "rgba(243, 243, 243, 0.5)",
        padding: "10px",
        outline: "none"
    },
    messageInputStyle: {
        padding: "10px 0",
        backgroundColor: "rgba(243, 243, 243, 0.5)",
    },
    errorMessageStyle: {
        padding: "10px 0",
        backgroundColor: "rgba(243, 243, 243, 0.5)",
        border: "2px solid red"
    },
    msgInputStyle: {
        width: "100%",
        height: "100px",
        border: "none",
        outline: "none",
        borderRadius: "4px",
        backgroundColor: "transparent",
        resize: "none",
        margin: "10px",
        fontFamily: 'Raleway',
        fontWeight: 400,
        color: "#25272999",
        fontSize: "16px",
        lineHeight: "14.09px"
    },
    errormsg: {
        paddingTop: "5px",
        color: "#c00",
        fontSize: "16px"
    },
    popOverOverlayStyles: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: -1,
        "@media(min-width:650px)": {
            display: 'none'
        }
    }
});
export default withStyles(styles)(ContactusView)
export { ContactusView }
// Customizable Area End
