// Customizable Area Start
import React, { useState, useEffect, useRef } from "react";
import { styled, Box, Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import CategoryFilterOption from "./CategoryFilterOption.web";

interface IChipTitleProps {
  filterData: { filterName: string, selectValues: string[], filterOptions: any };
  filterList: any[];
  index: number;
  chipClick: () => void;
}

interface ISizeValue {
  value:{
    min:string,
    max: string
  }
}

const CategoryFilter: React.FC<IChipTitleProps> = ({ filterData, chipClick, filterList,  index }) => {
  const [showAllOption, setshowAllOption] = useState<boolean>(false);
  const [filterColor, setFilterColor] = useState<boolean>(false);
  const [filterName, setFilterName] = useState<string>(filterData?.filterName)
  const [arr, setArr] = useState<any>([]);
  const [obj, setObj] = useState<any>({ min: "", max: "" });
  const [sizeCount, setSizeCount] = useState("");
  const [sizeObj, setSizeObj] = useState<any>([{ id: "width", value: { min: "", max: "" } }, { id: "depth", value: { min: "", max: "" } }, { id: "height", value: { min: "", max: "" } }]);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (arr.length !== 0) {
      setFilterColor(true)
      if( arr[0]?.min?.length ==0 && arr[0]?.max?.length ==0){
        setFilterColor(false);
      }
    }else if(obj.min.length !==0 || obj.max.length!==0){
setFilterColor(true)
    }
    else if(obj.min.length ===0 || obj.max.length ===0){
      setFilterColor(false)
          }
    else {
      setFilterColor(false)
    }

    sizeObj.map((val: any) => {
      if (val.value.min.length !== 0 || val.value.max.length !== 0) {
        setFilterColor(true);
        return;
      }
    })

    if (filterName !== "price" && filterName !== "size") {
      for (let i = 0; i < filterList.length; i++) {
        if (filterList[i].filterName === filterName) {
          filterList[i].selectedValues = arr;
        }
      }
    } else if (filterName === "price") {
      for (let i = 0; i < filterList.length; i++) {
        if (filterList[i].filterName === filterName) {
          filterList[i].selectedValues = [{ id: "price", value: arr[0] }];
        }
      }
    }
    else if (filterName === "size") {
      const count = sizeObj.reduce((acc: number, cur: ISizeValue) => (cur.value.min !== "" || cur.value.max !== "")  ? ++acc : acc, 0);
      setSizeCount(count);
      for (let i = 0; i < filterList.length; i++) {
        if (filterList[i].filterName === filterName) {
          filterList[i].selectedValues = sizeObj;
        }
      }
    }

    chipClick()
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [arr, sizeObj, filterList])

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setshowAllOption(false);
    }
  };

  return (
    <DropdownContainer style={{ position: "relative" }} ref={dropdownRef}>
      <ChipTitleContainer style={{ 
        background: filterColor || showAllOption || filterName === "categories" || filterName === "sub_categories" ? "#A27D4D" : "#FFF",
        border: showAllOption || filterColor || filterName === "categories" || filterName === "sub_categories"  ? "1px solid #A27D4D" : "1px solid rgba(37, 39, 41, 0.80)",
        padding: filterName === "sub_categories" ? (window.innerWidth <= 600 ? "":"13px 6px" ): "",
        }} onClick={() => setshowAllOption(!showAllOption)}>
        <Typography className="chip-name" style={{ color: showAllOption || filterColor || filterName === "categories" || filterName === "sub_categories" ? "#FFF" : "rgba(37, 39, 41, 0.80)" }}>
          {filterName.replace("_", "")}
          {((filterName === "categories") && (filterList[0].filterOptions.length > 0)) ? `(${filterList[0].filterOptions.length})` : ""}
          {((filterName === "sub_categories") && (filterList[1].filterOptions.length > 0)) ? `(${filterList[1].filterOptions.length})` : ""}
          {(filterName === "size") && Number(sizeCount) > 0 ? `(${sizeCount})` : ""}
          {(arr.length > 0) ? `(${arr.length})` : ""}
        </Typography>

        {!showAllOption ? <AddIcon className="plus-icon" style={{ color: filterColor || filterName === "categories" || filterName === "sub_categories" ? "#FFF" : "rgba(37, 39, 41, 0.8)" }} /> : <Box className="minus-icon"></Box>}
      </ChipTitleContainer>
      {showAllOption &&
        <Box className="record-container" style={{ position: "relative" }}>
          <OptionContainer style={{ zIndex: 20 - index }}>
            {filterData.filterOptions.map((item: any, inde: any) => (
              <CategoryFilterOption item={item} filterData={filterData} index={inde} chipClick={chipClick} arr={arr} setArr={setArr} obj={obj} setObj={setObj} sizeObj={sizeObj} setSizeObj={setSizeObj} key={`${filterName + inde}`} />
            ))}
          </OptionContainer>
        </Box>

      }
    </DropdownContainer>
  );
};

const DropdownContainer = styled('div')({
  width:'16.66%',
  padding:'0 8px',
  marginBottom:'15px',

  "@media(max-width:1260px)":{
    padding:'0 5px',
  },
  "@media(max-width:1170px)":{
    width:'20%',
  },
  "@media(max-width:1000px)":{
    width:'25%',
  },
  "@media(max-width:800px)":{
    width:'33.33%',
  },
  "@media(max-width:600px)":{
    margin: '15px',
    width:'80%',
  },

});
const ChipTitleContainer = styled(Box)(
  ({ theme }: { theme: any }) => ({
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "14px 14px",
    heigth: "56px",
    width: "100%",
    boxSizing: "border-box",
    cursor: "pointer",

    "& .chip-name": {
      fontFamily: "Raleway",
      fontSize: "16px",
      fontWeight: 600,
      textTransform: "uppercase",
      color: "#FFF",
    },
    "& .minus-icon": {
      width: "20px",
      height: "1.5px",
      background: "#FFF",
    },
    "& .plus-icon": {
      width: "20px",
      height: "20px",
      color: "rgba(37, 39, 41, 0.8)",
    },
    [theme.breakpoints.down("lg")]:{
      padding:"13px 24px",
      "& .chip-name":{
        fontSize:"16px",
      }
    },
    [theme.breakpoints.down("md")]: {
      padding: "13px 22px",
      "& .chip-name": {
        fontSize: "15px",
        fontWeight: 600
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "11px 20px",
      "& .chip-name": {
        fontSize: "12px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 18px",
      "& .chip-name": {
        fontSize: "10px",
      },
    },
  })
);

const OptionContainer = styled(Box)(
  ({ theme }: { theme: any }) => ({
    borderRadius: "5px",
    background: "#FFF",
    padding: 0,
    display: "flex",
    alignItems: "left",
    justifyContent: "space-between",
    boxSizing: "border-box",
    width: "100%",
    maxHeight: "80vh",
    overflowY: "scroll",
    overflowX: "-moz-hidden-unscrollable",
    scrollbarWidth: "thin",
    marginTop: "10px",
    position: "absolute",
    flexDirection: "column",

    border: "1px solid rgba(124, 124, 124, 0.8)",
    "& .option_container": { display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", width: "100%", },
    "& .checkbox_text": {
      color: "rgba(37, 39, 41, 0.80)",
      fontFamily: "Raleway", 
      fontSize: "16px", 
      fontWeight: 600,
    },
    "& .option_container_padding": {
      padding: "14px 16px"
    },
    "& .checkbox_style": { fontSize: "24px", marginRight: "16px", borderRadius: "4px" },
    "& .text_box":{ width: "85px", height: "40px"},
    "& .MuiInputAdornment-root.MuiInputAdornment-positionStart": {
      color: "rgba(37, 39, 41, 0.40)",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: 600,
    },
    "& .MuiInputAdornment-root.makeStyles-inputAdornment-70.MuiInputAdornment-positionEnd": {
      color: "rgba(37, 39, 41, 0.80)",
      fontFamily: "Raleway",
      fontSize: "17px",
      fontWeight: 600,
    },
    "& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined": {
      color: "rgba(37, 39, 41, 0.80)",
      fontFamily: "Raleway",
      fontSize: "17px",
      fontWeight: 600,
    },
    [theme.breakpoints.down("lg")]: {
     "& .text_box":{ width: "82px", height: "37px",fontSize:"14px"},
      "& .checkbox_text":{
        fontSize:"16px",
        fontWeight:600,
        width: "96px"
      },
      "& .option_container_padding": {
        padding:"12px 14px",
      },
     
    },
    [theme.breakpoints.down("md")]: {
      "& .text_box":{ width: "79px", height: "34px",fontSize:"14px"},
      "& .checkbox_text":{
        fontSize:"15px",
        fontWeight:600,
        width: "91px"
      },
      "& .option_container_padding": {
        padding:"10px 12px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "& .text_box":{ width: "76px", height: "31px",fontSize:"12px"},
      "& .checkbox_text":{
        fontSize:"12px",
        fontWeight:200,
        width:"86px"
      }, "& .option_container_padding": {
        padding:"8px 10px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      position: "inherit",
      "& .option_container_padding": {
        padding:"0px 10px",
      },
    },
  })
);

export default CategoryFilter;

// Customizable Area End
