Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getCategoriesApiEndPoint = "bx_block_navmenu/navigation_menu";
exports.getLandingPageDataApiEndPoint = "bx_block_landingpage2/landing_pages";
exports.getLandingPagePopUpApiEndPoint = "bx_block_landingpage2/landing_popups";
exports.getSocialMediaLinksApiEndPoint = "bx_block_landingpage2/footer_pages";
exports.getContactDetailsApiEndPoint = "bx_block_contact_us/contact_sections";
exports.guestOrdersApiEndPoint = "bx_block_shopping_cart/guest_orders";
exports.desiredSequence = ["Shop by room", "Accessories", "Art"];
exports.getOurTeamsSectionOnCareerPageEndpoint = "bx_block_landingpage2/our_teams";
exports.getOurTeamsSectionOnCareerPageMethod = "GET";
exports.careerPageListsEndpoint = "bx_block_landingpage2/career_pages"; 
exports.aboutUsTitle = "About Us";
exports.careersTitle = "Careers";
exports.headerEmdPoint = "bx_block_landingpage2/landingpage_about_us";
exports.valuesEmdPoint = "bx_block_landingpage2/our_values";
exports.teamEmdPoint = "bx_block_landingpage2/our_teams";
exports.ourTeam = "Our Team";
exports.ourValues = "Our Values";
exports.careerHeaderEndpoint = "bx_block_terms_and_conditions/headers";
exports.viewAll = "View all";
exports.careerDescriptionRegex = /\n\r/g
exports.headerScriptApiEndPoint = "bx_block_contact_us/header_scripts"
// Customizable Area End