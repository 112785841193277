// Customizable Area Start
import React, { useEffect, useState } from "react";
import { styled, Box, Typography, Checkbox, TextField, InputAdornment } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";

interface IChipTitleProps {
    filterData: any;
    item: any;
    chipClick: any
    arr: any;
    setArr: any;
    index: number;
    obj: any;
    setObj: any;
    sizeObj: any;
    setSizeObj: any
}

const useStyles = makeStyles({
    customCheckbox: {
        '&.Mui-checked': {
            color: "rgba(162, 125, 77, 1)",
        }
    },
    customTextField: {
        width:"78px",
        padding: "0",// Set the width as needed
        '& .MuiInputBase-input': {
            fontSize: '14px', // Set the font size for the input text
            fontWeight: 600,
            lineHeight: "21.09px",
            color: "rgba(37, 39, 41, 0.8)",

            // Set the text color
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'rgba(37, 39, 41, 0.6)', // Set the border color
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: 'rgba(37, 39, 41, 1)', // Change to your desired border color on focus
            },

        },
        '&:hover fieldset': {
            borderColor: 'rgba(37, 39, 41, 1)', // Set the border color on hover
        },
        '& .MuiInputLabel-root.Mui-focused': {
            color: 'rgba(37, 39, 41, 1)', // Change to your desired label color on focus
        },
        '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-outlined': {
            fontSize: "14px !important"
        }

    },
    inputAdornment: {
        position: 'absolute',
        right: 2,
        bottom: 12,
        fontFamily: "Raleway",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "14px",
        color: "rgba(37, 39, 41, 0.8)"
    },
});

const TypographyPriceSize = styled(Typography)({
    fontFamily: "Raleway",
    fontSize: "16px",
    fontWeight: 600, 
    lineHeight: "18.78px",
    "@media (max-width: 550px)": {
        fontSize: "10px",
      } 
});

const CategoryFilterOption: React.FC<IChipTitleProps> = ({ item, filterData, index, chipClick, setObj, obj, setSizeObj, sizeObj, setArr, arr }) => {
    const classes = useStyles();
    const [checked, setchecked] = useState<boolean>(false);
    const [min, setMin] = useState<string>("");
    const [max, setMax] = useState<string>("");
    const [optionObj, setOptionObj] = useState({ min: "", max: "" });

    const handleOption = () => {
        if (filterData.filterName === "categories" || filterData.filterName === "sub_categories") {

            if (arr.includes("" + item?.id)) {
                setchecked(true)
            }
        } else if (filterData.filterName === "material" || filterData.filterName === "colour") {
            if (arr.includes(item)) {
                setchecked(true)
            }
        } else if (filterData.filterName === "price") {
            if (arr.length !== 0) {
                if (arr[0].min.length !== 0) {
                    setMin(arr[0].min)
                }
                if (arr[0].max.length !== 0) {
                    setMax(arr[0].max)
                }
            }
        } else if (filterData.filterName === "size") {
            sizeObj.map((val: any) => {
                if (val.id == item.id) {
                    setMin(val.value.min)
                    setMax(val.value.max)
                }
            }
            )
        }
    }

    useEffect(() => {
        handleOption();
    }, [])

    const handleChange = (e: any, filter: string) => {
            let regex = /^[0-9]*\.?[0-9]*$/
          const eventData = e.target.value
        if (filter !== "price" && filter !== "size") {
            setchecked(!checked)
            if (e.target.checked) {

                setArr([...arr, e.target.value])
            } else {
                setArr(arr.filter((ele: any) => ele !== e.target.value))
            }
        } else if(eventData.match(regex)) {       
        if (filter === "price") {
            if (e.target.name === "min") {
                setMin(e.target.value);
                setObj({ ...obj, min: e.target.value })
            } 
            if((e.target.name === "max") ) {
                setMax(e.target.value)
                setObj({ ...obj, max: e.target.value })
            }
            setArr([obj])
        }
        else if (filter === "size") {

            if (e.target.name === "min") {
                setMin(e.target.value);
                setOptionObj({ ...optionObj, min: e.target.value })
            } else {
                setMax(e.target.value);
                setOptionObj({ ...optionObj, max: e.target.value })
            }

        }
     }

    }

    const handleSizeupdate = (updatedItem: any) => {
        const updatedItems = sizeObj.map((val: any) =>
            val.id === updatedItem.id ? updatedItem : val
        );
        setSizeObj(updatedItems)
    }

    useEffect(() => {
        if (arr.length !== 0) {
            if (arr[0].min || arr[0].max) {
                setArr([obj])
            }
        }
        handleSizeupdate({ id: item.id, value: optionObj })


    }, [obj, optionObj])

    return (



        (item?.name) ?

            <Box className={"option_container option_container_padding"} style={{ borderBottom: filterData.filterOptions[index + 1] && "1px solid rgba(37,39,41,0.2)", }} >

                <Checkbox
                    disabled 
                    checked 
                    className={`${classes.customCheckbox} checkbox_style`}
                    inputProps={{ 'aria-label': 'disabled checked checkbox' }}
                    value={item.id}
                />
                <Typography className="checkbox_text">{item?.name}</Typography>
            </Box>


            : (filterData.filterName === "colour" || filterData.filterName === "material") ?

                <Box className={"option_container option_container_padding"} style={{ borderBottom: "1px solid rgba(37,39,41,0.2)", }} >
                    <Checkbox
                        checked={checked}
                        className={`${classes.customCheckbox} checkbox_style`}
                        onChange={(e) => handleChange(e, filterData.filterName)}
                        value={item}
                    />
                    <Typography className={"checkbox_text"}>{item}</Typography>
                </Box>

                :

                <Box style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%", padding: "12px" }}>
                    <TypographyPriceSize>{item.title? item.title : item}</TypographyPriceSize>
                    <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", padding: 0, marginTop: "18px" }}>
                        <TextField
                            value={min}
                            name="min"
                            variant="outlined"
                            label="Min"
                            onChange={(e) => handleChange(e, filterData.filterName)}
                            id="outlined-start-adornment"
                            className={`${classes.customTextField}`}
                            
                            InputProps={{
                                startAdornment: filterData.filterName === "price" ? <InputAdornment position="start">£</InputAdornment> : <InputAdornment position="start">{""}</InputAdornment>,
                                endAdornment: filterData.filterName === "size" ? <InputAdornment position="end" className={classes.inputAdornment}>cm</InputAdornment> : null
                            }}
                        />
                        <TextField
                            label="Max"
                            value={max}
                            name="max"
                            onChange={(e) => handleChange(e, filterData.filterName)}
                            variant="outlined"
                            id="outlined-start-adornment"
                            className={`${classes.customTextField}`}
                            InputProps={{
                                startAdornment: filterData.filterName === "price" ? <InputAdornment position="start">£</InputAdornment> : <InputAdornment position="start">{""}</InputAdornment>,
                                endAdornment: filterData.filterName === "size" ? <InputAdornment position="end" className={classes.inputAdornment}>cm</InputAdornment> : null
                            }}
                        />
                    </Box>

                </Box>
    );
};



export default CategoryFilterOption;

// Customizable Area End
