import React from "react";

// Customizable Area Start
import { Box, styled } from "@material-ui/core";
// Customizable Area End

import VideosController, { Props, configJSON } from "./VideosController";

export default class Videos extends VideosController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderVideo = () => {
    if (window.location.pathname === "/ProductDescription") {
      return <>
        <video ref={this.videoRef} style={{ width: "100%", maxHeight: "100%" }} controls>
          <source src={this.props.videoUrl} type="video/mp4" />
        </video>
      </>
    }

    return <>
      <video width={"100%"} height={"auto"} controls>
        <source src={this.props.videoUrl} type="video/mp4" />
      </video>
    </>
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <VideoPlayer>
        {this.renderVideo()}
      </VideoPlayer>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const VideoPlayer = styled(Box)({
  position: "relative",
  display: "flex",
  width: "100%",
  height: "100%",
  "& .custom-btn" : {
    width: "60px",
    height: "60px",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: "none",
    border: 0
  }
})
// Customizable Area End
