import React from "react";

// Customizable Area Start
import Header from "../../../components/src/Header.web";
import Footer from "../../../components/src/Footer.web";
const configJSON = require("./config.js")
// Customizable Area End

import HeaderControllerWeb, { Props } from "./HeaderController.web";

export default class HeaderWeb extends HeaderControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const { children, navigation } = this.props;
    const { menuToggle, categories, socialMediaLinks, cartItems, contactDetails } = this.state;
    return (
      // Customizable Area Start
      <>
        <Header
          menuToggle={menuToggle}
          showModal={this.showModal}
          hideModal={this.hideModal}
          categories={categories}
          navigation={navigation}
          cartItems={cartItems}
        />
        <main>{children}</main>
        {socialMediaLinks && !["/Settings2", "/Chat"].some((path) => window.location.pathname.includes(path)) &&
          <Footer
            socialMediaLinks={socialMediaLinks}
            contactDetails={contactDetails}
          />}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
