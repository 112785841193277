// Customizable Area Start
export const cart = require("./cart.svg");
export const contact = require("./contact.svg");
export const email = require("./email.svg");
export const facebook = require("./facebook.svg");
export const instagram = require("./instagram.svg");
export const linkedin = require("./linkedin.svg");
export const mainLogo = require("./mainLogo.png");
export const search = require("./search.svg");
export const twitter = require("./twitter.svg");
export const loginSideImage = require("./login_main_image.png");
export const successIcon = require("./success.svg");
export const closeIcon = require("./close.svg");
export const checkWhiteIcon = require("./check_white.svg");
export const banner = require("./banner.jpg");
export const notFoundImage = require("./not_found.png");
export const back = require("./back.svg");
export const logo = require("./logo.png");
export const profileIcon = require("./ProfileIcon.svg");
export const menuIcon = require("./menuIcon.png");
export const menuIconClean = require("./menuIcon.svg")
// Customizable Area End
