import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
export interface ContactusData {
  data: {
    id: number,
    attributes: {
      name: string,
      email: string,
      phone_number: string,
      subject: string,
      details: string,
    }
  }
}

interface ContactusError {
  errors: [
    {
      contact: [
        ""
      ]
    }
  ]
}

interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
}
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: any;
  classes: Record<string, string>;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isName:boolean;
  isEmail:boolean;
  isPhoneNumber:boolean;
  isMessage:boolean;
  name: string;
  email: string;
  phoneNumber: string;
  message: string;
  anchorEl: null | HTMLElement;
  backOverlayOpen:boolean;
  isInvalidPhoneNumber: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ContactusViewController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  postContactusCallID: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      isName:false,
      isEmail:false,
      isPhoneNumber:false,
      isMessage:false,
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      anchorEl: null,
      backOverlayOpen:false,
      isInvalidPhoneNumber: false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start

    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
     
      if (responseJson && !responseJson.errors) {
        this.responseContactusSuccessCall(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.responseContactusFailureCall(apiRequestCallId, responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  onChangeName = (text: string) => {
    this.setState({ name : text, isName:false});
  };

  onChangeEmail = (text: string) => {
    this.setState({ email:text,isEmail:false });
  };

  onChangePhone = (text: string) => {
    this.setState({ phoneNumber: text.replace(/\D/g, ""), isPhoneNumber:false });
  };

  onChangeMessage = (text :string) => {
    this.setState({ message: text,isMessage:false });
  };

  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;

    const requestMessageCatalogueColorCollection = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headerCatalogueColorCollection = {
      "Content-Type": contentType,
    };
    
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
   
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerCatalogueColorCollection)
    );
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageCatalogueColorCollection.id, requestMessageCatalogueColorCollection);
    return requestMessageCatalogueColorCollection.messageId;
  };



  postContactusFunction = async () => {
    let apiData = {
      data: {
        name: this.state.name.trim(),
        email: this.state.email.trim(),
        phone_number: `44${this.state.phoneNumber.trim()}`,
        //subject: "okkkk",
        details: this.state.message.trim()
      }
    }
    this.postContactusCallID = await this.apiCall({
      contentType: configJSON.contactUsApiContentTypeWeb,
      method: configJSON.contactUsApiMethodWeb,
      endPoint: configJSON.contactUsApiEndPointWeb,
      body: apiData,
    });
  };

  responseContactusSuccessCall = async (apiRequestCallId: string, responseJson: ContactusData) => {
    if (apiRequestCallId === this.postContactusCallID) {
      this.contactusSuccessCallBack(responseJson);
    }
  }

  responseContactusFailureCall = async (apiRequestCallId: string, errorReponse: ContactusError) => {
    if (apiRequestCallId === this.postContactusCallID) {
      this.contactusFailureCallBack(errorReponse);
    }
  }

  contactusSuccessCallBack = (responseJson: ContactusData) => {
    this.handlePopoverClose()
    toast.success("Thank you for contacting us! Your message has been sent successfully.");
  };

  contactusFailureCallBack = (responseJson: ContactusError) => { 
    toast.error(responseJson.errors[0].contact[0], {
      toastId: 'errorOne',
  })
  }
 
  handleValidation = (event: React.ChangeEvent<HTMLFormElement>) =>{
     event?.preventDefault();
     const numberRegex = configJSON.phoneRegex;
     if (this.state.name === "" && this.state.email === "" && this.state.phoneNumber === "" && this.state.message === "") {
      this.setState({
        isName:true,
        isEmail:true,
        isPhoneNumber:true,
        isMessage:true
      });
    } 
    else if(this.state.name.trim().length < 2){
      this.setState({isName:true})
    }
    else if(!configJSON.emailRegex.test(this.state.email.trim())){
      this.setState({isEmail:true})
    }

    else if(this.state.phoneNumber.trim().length === 0){
      this.setState({isPhoneNumber: true})
    } 
    else if (!numberRegex.test(this.state.phoneNumber)) {
      this.setState({isInvalidPhoneNumber: true});
    }
    else if(this.state.message.trim().length === 0){
      this.setState({isMessage:true})
    }
    else{
      this.postContactusFunction()
    }
  }

  handlePopoverOpen = (event:{currentTarget: null | HTMLElement}) => {
    this.setState({
      anchorEl: event.currentTarget,
      backOverlayOpen:true
    });
  };

  handlePopoverClose = () => {
    this.setState({
      anchorEl: null,
      name: "",
      email: "",
      phoneNumber:"",
      message: "",
      backOverlayOpen:false,
      isEmail: false,
      isName: false,
      isMessage: false,
      isPhoneNumber: false,
      isInvalidPhoneNumber: false
    });
  };

  phoneValidation = () => {
    return this.state.isPhoneNumber || this.state.isInvalidPhoneNumber;
  };
  // Customizable Area End
}
