import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  menuToggle: boolean;
  categories: any[];
  socialMediaLinks: {
    id: string;
    type: string;
    attributes: {
      fb_link: string;
      linkedin_link: string;
      instagram_link: string;
      twitter_link: string;
    };
  };
  cartItems: number;
  cartUpdate: boolean;
  contactDetails: {
    id: string;
    type: string;
    attributes: {
      email: string;
      full_phone_number: string;
    }
  }
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class HeaderControllerWeb extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCategoriesApiCallId: string = "";
  getSocialMediaLinksApiCallId: string = "";
  getCartItemsApiCallId: string = "";
  getContactDetailsApiCallId: string = "";
  getHeaderApiCallID: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      menuToggle: false,
      categories: [],
      socialMediaLinks: {
        id: "",
        type: "",
        attributes: {
          fb_link: "",
          instagram_link: "",
          linkedin_link: "",
          twitter_link: "",
        },
      },
      cartUpdate: false,
      cartItems: 0,
      contactDetails: {
        id: "",
        type: "",
        attributes: {
          email: "",
          full_phone_number: ""
        }
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start
    this.getCategories();
    this.getSocialMediaLinks();
    this.getCartItems()
    this.getContactDetails()
    this.getHeaderScript()
    window.addEventListener("toggleCartUpdate", (event: any) => {
      this.setState({ cartUpdate: event.detail });
    });
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Props, prevState: S) {
    if (prevState.cartUpdate !== this.state.cartUpdate) {
      if (this.state.cartUpdate) {
        this.getCartItems();
      }
    }
  }

  showModal = () => {
    this.setState({ menuToggle: true });
  };

  hideModal = () => {
    this.setState({ menuToggle: false });
  };

  getHeaderData(message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      if (apiRequestCallId === this.getHeaderApiCallID) {
        responseJson.forEach((element: any) => {
          const parser = new DOMParser();
          const doc = parser.parseFromString(element.text, 'text/html');

          Array.from(doc.head.childNodes).forEach(node => {
            document.head.appendChild(node.cloneNode(true));
          });
        });
      }
    }
  }
  
  getHeaderScript = () => {
    const PCheaders = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const getAllData = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getHeaderApiCallID = getAllData.messageId;

    getAllData.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.headerScriptApiEndPoint);

    getAllData.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(PCheaders));

    getAllData.addData(getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getAllData.id, getAllData);

  }
  getCategories = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCategoriesApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getContactDetails = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getContactDetailsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getContactDetailsApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getSocialMediaLinks = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSocialMediaLinksApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSocialMediaLinksApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCartItems = async () => {
    let userLoginId = await getStorageData("user_id")
    let tempUserId = await getStorageData("TempId")
    let CartUserId = userLoginId ? userLoginId : tempUserId;

    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCartItemsApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.guestOrdersApiEndPoint}?account_id=${CartUserId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      this.getCategoriesApiCallId !== null &&
      this.getCategoriesApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const desiredSequence = configJSON.desiredSequence

      const filteredAndSortedData = responseJson &&
        responseJson.filter((item: any) => item.data.attributes.position !== "Interior Designers")
          .sort((a: any, b: any) => {
            const positionA = desiredSequence.indexOf(a.data.attributes.position);
            const positionB = desiredSequence.indexOf(b.data.attributes.position);
            return positionA - positionB;
          });
      this.setState({ categories: filteredAndSortedData });
    }
    if (
      this.getSocialMediaLinksApiCallId !== null &&
      this.getSocialMediaLinksApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ socialMediaLinks: responseJson.data });
      }
    }
    if (this.getCartItemsApiCallId !== null &&
      this.getCartItemsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        let totalQuantity = 0;
        responseJson.data[0].attributes.data.forEach((category: any) => {
          category.products.forEach((product: any) => {
            totalQuantity += product.quantity;
          });
        });
        this.setState({ cartItems: totalQuantity, cartUpdate: false })
      }
    }
    if (this.getContactDetailsApiCallId !== null &&
      this.getContactDetailsApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ contactDetails: responseJson.data })
      }
    }
    this.getHeaderData(message)
    // Customizable Area End
  }
}
