import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

// Customizable Area Start
import { apiCall } from "../../../components/src/commonUtils.web";
import { setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  categoriesArray: any;
  category: string;
  subCategory: string;
  isVisible: boolean;
  dropdownCategoryStatus: boolean;
  activeModalType: string;
  selectedCategoryID: any;
  subCategoryList: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      title: string;
      description: string;
      product_type: string;
      category: {
        id: number;
        name: string;
      };
      sub_cat_icon: string;
    };
  }[];
  paginatedSubcategoies: {
    id: string;
    type: string;
    attributes: {
      id: number;
      name: string;
      title: string;
      description: string;
      product_type: string;
      category: {
        id: number;
        name: string;
      };
      sub_cat_icon: string;
    };
  }[];
  categoryId: string;
  isLoading: boolean;
  isProductsLoading: boolean;
  title: string;
  description: string;
  productId: string;
  catalogueId: string;
  productList: {
    id: string;
    type: string;
    attributes: {
      name: string;
      maker: string;
      maker_description: string;
      price: number;
      colour: string;
      length: number;
      width: number;
      height: number;
      description: string;
      details: string;
      style: string;
      year_of_release: number;
      product_sheet_link: string;
      sku: string;
      category: {
        id: number;
        name: string;
        image: string;
      };
      sub_category: {
        id: number;
        name: string;
        image: string;
      };
      maker_image: string;
      product_photos: string[];
    };
  }[] | null;
  allFiltersList: any;
  filtersList: any;
  webDrawer: boolean;
  currentPaginationNumber: number;
  currentProductPageNumber: number;
  totalProductCount: number;
  totalProductPages: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class CategoriessubcategoriesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCategoriesApiCallId: any;
  deleteCategoriesApiCallId: any;
  deleteSubCategoriesApiCallId: any;
  addCategoryApiCallId: any;
  addSubCategoryApiCallId: any;
  subCategoryListApiCallId: any;
  productListApiCallId: string = "";
  filtersListApiCallId: any;
  postFiltersListCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start

    this.handleSubcategoryPaginationChange = this.handleSubcategoryPaginationChange.bind(this);
    this.calculateItemsToShow = this.calculateItemsToShow.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",

      currentPaginationNumber: 1,
      categoriesArray: [],
      paginatedSubcategoies: [],
      category: "",
      subCategory: "",
      isVisible: false,
      dropdownCategoryStatus: false,
      activeModalType: "",
      selectedCategoryID: [],
      subCategoryList: [],
      categoryId: "",
      isLoading: false,
      isProductsLoading: false,
      description: "",
      title: "",
      productId: "",
      catalogueId: "",
      productList: null,
      allFiltersList:{},
      filtersList:[],
      webDrawer: false,
      currentProductPageNumber: 1,
      totalProductCount: 0,
      totalProductPages: 0,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    const catalogueId = this.props.navigation.getParam("catalogueId");
    const categoryId = this.props.navigation.getParam("categoryId");
    if (categoryId && catalogueId) {
      const productId = this.props.navigation.getParam("productId");
      if (productId) {
        this.setState(
          {
            productId,
            catalogueId,
          },
          () => {
            this.getProductApiCall();
            this.filterAPiCall();

          }
        );
      } else {
        this.setState(
          {
            categoryId,
            catalogueId,
          },
          () => {
            this.subCategoryListApiCall();
          }
        );
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidUpdate(prevProps: Props, prevState: S) {
    const catalogueId = this.props.navigation.getParam("catalogueId");
    const categoryId = this.props.navigation.getParam("categoryId");
    if (categoryId !== this.state.categoryId || catalogueId !== this.state.catalogueId) {
      const productId = this.props.navigation.getParam("productId");
      if (productId !== this.state.productId) {
        this.setState({ productId, catalogueId },
          () => {
            this.getProductApiCall();
            this.filterAPiCall();
          });
      } else {
        this.setState({ categoryId, catalogueId },
          () => {
            this.subCategoryListApiCall();
          }
        );
      }
    }
  }

  getToken = () => {
    const token: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );

    this.send(token);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId2 = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId2 && responseJson) {
        this.handleApiResponse(apiRequestCallId2, responseJson);
      }

      this.setState({
        isLoading: false,
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  formatPrice(price: any) {
    if (price) {
      return price.toLocaleString('en-GB');
    }
  }

  subCategoryListApiCall = async () => {
    this.setState({
      isLoading: true,
    });
    this.subCategoryListApiCallId = await apiCall({
      endPoint: `${configJSON.subCategoryListApiEndPoint1}/${this.state.categoryId
        }/${configJSON.subCategoryListApiEndPoint2}`,
      method: configJSON.httpGetType,
      contentType: configJSON.subCategoryListApiContentType,
    });
  };

  getProductApiCall = async () => {
    this.setState({
      isProductsLoading: true,
    });
    this.productListApiCallId = await apiCall({
      endPoint: `${configJSON.productListApiEndPoint1}/${this.state.productId
        }/${configJSON.productListApiEndPoint2}?per_page=15&page=${this.state.currentProductPageNumber}`,
      method: configJSON.httpGetType,
      contentType: configJSON.productListApiContentType,
    });

  };

  productDataFilterSize = (optionsData: any, nameFilter: any, formdata: FormData) => {
    optionsData.forEach((val: any) => {
      if (val.id === "height") {
        val.value.min && formdata.append(`${nameFilter}[height][min]`, val.value.min)
        val.value.max && formdata.append(`${nameFilter}[height][max]`, val.value.max)
      }
      if (val.id === "depth") {
        val.value.min && formdata.append(`${nameFilter}[depth][min]`, val.value.min)
        val.value.max && formdata.append(`${nameFilter}[depth][max]`, val.value.max)
      }
      if (val.id === "width") {
        val.value.min && formdata.append(`${nameFilter}[width][min]`, val.value.min)
        val.value.max && formdata.append(`${nameFilter}[width][max]`, val.value.max)
      }
    })
  }
  productFilter = (options: any, filterName: any, formdata: FormData) => {
    options.forEach((fiterVal: string) => {
      formdata.append(`${filterName}[]`, fiterVal);
    })
  }
  productFilterPrice = (options: any, filterName: any, formdata: FormData) => {
    options.forEach((valPrice: any) => {
      if (valPrice.value?.min && valPrice.value?.max) {
        formdata.append(`${filterName}[min]`, valPrice.value?.min)
        formdata.append(`${filterName}[max]`, valPrice.value?.max)
      }
    })
  }

  productFilterData = () => {
    const formdata = new FormData();
    const { filtersList } = this.state;

    formdata.append(`category[]`, this.state.categoryId);
    formdata.append(`sub_category[]`, this.state.productId);
    filtersList.forEach((filter: any) => {
      const filterName = filter.filterName;
      const options = filter.selectedValues;

      if (options?.length > 0 && (filterName === "colour" || filterName === "material")) {
        this.productFilter(options, filterName, formdata)
      }

      options?.length > 0 && filterName === "price" && this.productFilterPrice(options, filterName, formdata)
      options?.length > 0 && filterName === "size" && this.productDataFilterSize(options, filterName, formdata)

    })
    return formdata;
  }

  productFilterApiCall = async () => {
    const totalProductPages = this.state.totalProductPages;
    const currentProductPageNumber = this.state.currentProductPageNumber;
    if(totalProductPages !== 0){
      this.postFiltersListCallId = await apiCall({
        endPoint: `${configJSON.postFilterApiEndPoint}?per_page=15&page=${currentProductPageNumber}`,
        method: configJSON.httpPostType,
        body: this.productFilterData()
      })
    }
  }
  filterAPiCall = async () => {
    this.filtersListApiCallId = await apiCall({
      endPoint: `${configJSON.filtersApiEndPoint}`,
      method: configJSON.httpGetType,
      contentType: configJSON.filtersApiContentType,
    })
  }


  subCategoryListHandleResponse = (responseJson: any) => {
    if (responseJson.data) {
      this.setState({
        subCategoryList: responseJson.data.attributes.sub_categories.data,
        title: responseJson.data.attributes.title,
        description: responseJson.data.attributes.description,
      }, () => {
        this.calculateItemsToShow()
      });
    }
  };

  productListHandleResponse = (responseJson: any) => {
    if (responseJson.data) {
      const uniqueSfxValues: any = {};
      const filteredArray: any = [];
      responseJson.data.attributes.products.data.forEach((item: any) => {
        if (!uniqueSfxValues[item.attributes.sku]) {
          uniqueSfxValues[item.attributes.sku] = true;
          filteredArray.push(item);
        }
      });
      this.setState({
        isProductsLoading: false,
        productList: filteredArray,
        title: responseJson.data.attributes.sub_category.data.attributes.name,
        description:
          responseJson.data.attributes.sub_category.data.attributes.description,
      });
    }
    this.setState({totalProductCount: responseJson.totalCount, totalProductPages: responseJson.totalPages },()=>this.setScrollProduct())
  };

  postFiltersListHandleResponse = (responseJson: any) => {
  const { data, message } = responseJson;
  const productList = message ? [] : data || [];
  this.setState({ productList , totalProductPages : responseJson.totalPages},()=>this.setScrollProduct());
  }

  setScrollProduct = () => {
    const element = document.getElementById("content");
    element && element.scrollIntoView({ behavior: "smooth" });
  }

  filterListResponceJsonCategory = (key: string, responseJson: any) => {
    const optionCategory: any[] = []
    responseJson[key].map((item: any) => {
      if (item.id.toString() === this.state.categoryId) {
        optionCategory.push(item)
      }
    })
    this.setState({ filtersList: [...this.state.filtersList, { filterName: key, isSelected: true, selectedValues: [this.state.categoryId], filterOptions: optionCategory }] })
  }

  filterListResponceJsonSubCategory = (key: string, responseJson: any) => {
    const optionsubCategory: any[] = []
    responseJson[key].map((item: any) => {
      if (item.id.toString() === this.state.productId) {
        optionsubCategory.push(item);
      }
    })
    this.setState({ filtersList: [...this.state.filtersList, { filterName: key, selectedValues: [this.state.subCategory], filterOptions: optionsubCategory }] })
  }

  filtersListHandleResponse = (responseJson: any) => {
    if (responseJson) {
      for (let key in responseJson) {
        if (key === "categories") {
          this.filterListResponceJsonCategory(key, responseJson)
        } else if (key === "sub_categories") {
          this.filterListResponceJsonSubCategory(key, responseJson)
        } else {
          this.setState({ filtersList: [...this.state.filtersList, { filterName: key, selectedValues: [], filterOptions: responseJson[key] }] })
        }
      }
      this.setState({ filtersList: [...this.state.filtersList, { filterName: "price", selectValues: [{ id: "price", value: { min: "", max: "" } }], filterOptions: ["Price Range"] }] })
      this.setState({ filtersList: [...this.state.filtersList, { filterName: "size", selectValues: [{ id: "width", value: { min: "", max: "" } }, { id: "depth", value: { min: "", max: "" } }, { id: "height", value: { min: "", max: "" } }], filterOptions: [{ title: "Overall Width", id: "width" }, { title: "Overall Depth", id: "depth" }, { title: "Overall Height", id: "height" }] }] })
    }
  }

  navigateToProductDescrption (data: any) {
    data && this.props.navigation.navigate("ProductDescription", {
      params: JSON.stringify({
        colour: data.attributes.colour,
        sku: data.attributes.sku,
        height: data.attributes.height,
        width: data.attributes.width,
        length: data.attributes.length
      })
    })
  }

  handleApiResponse = (apiRequestCallId: string, responseJson: any) => {
    switch (apiRequestCallId) {
      case this.subCategoryListApiCallId:
        this.subCategoryListHandleResponse(responseJson);
        break;
      case this.productListApiCallId:
        this.productListHandleResponse(responseJson);
        break;
      case this.filtersListApiCallId:
        this.filtersListHandleResponse(responseJson);
        break;
      case this.postFiltersListCallId:
        this.postFiltersListHandleResponse(responseJson);
        break;
      default:
        break;
    }
  };

  setCategoryTxt = (text: string) => {
    this.setState({ category: text });
  };
  setSubCategoryTxt = (text: string) => {
    this.setState({ subCategory: text });
  };
  clickCategory = (item: any, Index: number) => {
    let array = this.state.categoriesArray;
    let idarray = this.state.selectedCategoryID;
    let index = idarray.indexOf(item.attributes.id);

    if (index > -1) {
      idarray.splice(index, 1);
      array[Index].Check = false;
      this.setState({ categoriesArray: array });
    } else {
      idarray.push(item.attributes.id);
      array[Index].Check = true;
      this.setState({ categoriesArray: array });
      this.setState({ selectedCategoryID: idarray });
    }
  };

  toggleModal = (type: string) => {
    this.setState({ activeModalType: type, isVisible: !this.state.isVisible });
  };

  expandCategoryView = () => {
    this.setState({
      dropdownCategoryStatus: !this.state.dropdownCategoryStatus,
    });
  };
  expand = (id: string) => {
    let array = this.state.categoriesArray;
    for (let i = 0; i < array.length; i++) {
      if (array[i].id === id) {
        array[i].expand = !array[i].expand;
      }
    }
    this.setState({ categoriesArray: array });
  };

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  addCategory = () => {
    if (this.isStringNullOrBlank(this.state.category)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else {
      let data = {
        categories: [{ name: this.state.category }],
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.categoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };
  addSubCategory = () => {
    if (this.isStringNullOrBlank(this.state.subCategory)) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    } else if (this.state.selectedCategoryID.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorCategory);
      return false;
    } else {
      let data = {
        sub_category: {
          name: this.state.subCategory,
        },
        parent_categories: this.state.selectedCategoryID,
      };
      const header = {
        "Content-Type": configJSON.categoryApiContentType,
        token: this.state.token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.addSubCategoryApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.subCategoryAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(data)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
    }
  };

  deleteCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  deleteSubCategories = (id: number) => {
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteSubCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subCategoryAPIEndPoint + `/${id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getCategories = () => {
    if (!this.state.token) {
      return;
    }

    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: this.state.token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  toggleDrawer = (event: any) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    this.setState({ webDrawer: !this.state.webDrawer });
  };

  handleSubcategoryPaginationChange(event: React.ChangeEvent<unknown>, value: number) {
    this.setState({ currentPaginationNumber: value }, () => {
       this.calculateItemsToShow()
    })
  }

  calculateItemsToShow = () => {
    const startIndex = (this.state.currentPaginationNumber - 1) * 3;
    const endIndex = startIndex + 3;
    this.setState({ paginatedSubcategoies: this.state.subCategoryList.slice(startIndex, endIndex) });
  };

  handleProductPagination = (event: React.ChangeEvent<unknown>, value: number) =>{
    this.setState({ currentProductPageNumber : value}, ()=>this.getProductApiCall())
  }
  
  noProduct = () => {
    return this.state.productList !== null && this.state.productList.length === 0;
  };

  getProductList = () => {
    return this.state.productList !== null && this.state.productList.length > 0;
  };
  // Customizable Area End
}
