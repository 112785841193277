import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
interface InteriorDesigner {
    type: string;
    id: string;
    attributes: InteriorDesignerAttributes;
}

interface InteriorDesignerAttributes {
    email: string;
    full_name: string | null;
    vat_number: string;
    phone_number: number;
    about_us: string;
    billing_address: string;
    promo_code: string | null;
    company_name: string | null;
    website_link: string;
    activated: boolean;
    tag_line: string;
    banner_image: string;
    product_photos: {
        blob_id: number;
        url: string;
    }[];
    profile_picture: string;
    designer_videos: string[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    interiorDesignerProfile: InteriorDesigner;
    tab_value: number;
    hero_image: string;
    designerDescription: string;
    EditProfile: boolean,
    currentSlide: string;

    editType: string | null;
    isEditing: boolean;
    newHeading: string;
    newBannerImage: File | null;
    newProfileImage: File | null;
    newProductImage: FileList | null;
    bannerImagePreview: string;
    profileImagePreview: string;
    isLoading: boolean;
    updatedFullName: string;
    updatedAboutUs: string;
    updatedTagLine: string;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EditInteriorDesignerProfilesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getDesignersApiCallId: string = "";
    updateDesignerDetailsApiCallId: string = '';
    deleteProductImageApiCallId: string = '';
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.RestAPIResponceDataMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tab_value: 0,
            interiorDesignerProfile: {
                id: "",
                type: "",
                attributes: {
                    email: "",
                    designer_videos: [],
                    vat_number: "",
                    phone_number: 0,
                    about_us: "",
                    billing_address: "",
                    promo_code: "",
                    company_name: "",
                    website_link: "",
                    activated: false,
                    product_photos: [{
                        blob_id: 0,
                        url: ""
                    }],
                    profile_picture: "",
                    full_name: "",
                    tag_line: "",
                    banner_image: ""
                }
            },
            hero_image: "",
            designerDescription: "",
            EditProfile: false,
            currentSlide: "",

            editType: null,
            isEditing: false,
            newHeading: '',
            newBannerImage: null,
            newProfileImage: null,
            newProductImage: null,
            bannerImagePreview: "",
            profileImagePreview: "",
            isLoading: false,
            updatedAboutUs: '',
            updatedFullName: '',
            updatedTagLine: ''
            // Customizable Area End
        };

        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        super.componentDidMount();
        
        this.getDesignersId();
        // Customizable Area End
    }

    // Customizable Area Start
    getDesignersId = async () => {
        const designerId = await getStorageData('user_id')
        this.getDesigners(designerId)
    }

    handleGoBacks = () => {
        this.props.navigation.goBack()
    }

    setScrollRestorations() {
        const element = document.getElementById("content");
        element && element.scrollIntoView({ behavior: "smooth" });
    }

    selectedHeroImage = (imageIndex: string) => {
        this.setState({ currentSlide: imageIndex })
    };

    getDesigners = async (designerId: string) => {
        const header = {
            "Content-Type": configJSON.editApiContentType,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getDesignersApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getInteriorDesignerApiEndPoint}/${designerId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (this.getDesignersApiCallId != null &&
            this.getDesignersApiCallId === apiRequestCallId
        ) {
            if (responseJson.data) {
                this.setState({
                    interiorDesignerProfile: responseJson.data,
                });
                let currentSlide = responseJson.data.attributes.product_photos[0].url;
                this.getSlide(currentSlide);
            }
        }
        if (this.updateDesignerDetailsApiCallId != null &&
            this.updateDesignerDetailsApiCallId === apiRequestCallId
        ) {
            if (responseJson.data) {
                this.setState({
                    interiorDesignerProfile: responseJson.data,
                    currentSlide: responseJson.data.attributes?.product_photos ? responseJson.data.attributes.product_photos[0].url : "",
                    isLoading: false
                });
            }
            else {
                this.setState({ isLoading: false })
                toast.error("Something went wrong")
            }
        }
        if (this.deleteProductImageApiCallId != null &&
            this.deleteProductImageApiCallId === apiRequestCallId
        ) {
            if (responseJson) {
                this.setState({ isLoading: false })
                this.getDesignersId()
            }
        }
        // Customizable Area End
    }

    handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Handle image selection
        const file = event.target.files?.[0] || null;
        if (this.state.editType === 'bannerImage') {
            this.setState({ newBannerImage: file, bannerImagePreview: URL.createObjectURL(file as Blob) });
        }
        else {
            this.setState({ newProfileImage: file, profileImagePreview: URL.createObjectURL(file as Blob) });
        }
    };

    uploadProductImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        this.setState({ newProductImage: fileList },
            () => this.handleSave(event))
    }

    handleAboutUsChange = (value: string) => {
        this.setState({ updatedAboutUs: value })
    }

    handleNameChange = (value: string) => {
        this.setState({ updatedFullName: value })
    }

    handleTagChange = (value: string) => {
        this.setState({ updatedTagLine: value })
    }

    handleEdit = (editType: string) => {
        this.setState({ 
            isEditing: true, 
            editType: editType, 
            updatedFullName: this.state.interiorDesignerProfile.attributes.full_name as string,
            updatedTagLine: this.state.interiorDesignerProfile.attributes.tag_line,
            updatedAboutUs: this.state.interiorDesignerProfile.attributes.about_us,
        });
    };

    deleteCurrentImage = async () => {
        let imageIndex = this.state.interiorDesignerProfile.attributes.product_photos?.findIndex(obj => obj.url === this.state.currentSlide);
        this.setState({ isLoading: true })
        const header = { "token": await getStorageData("TOKEN") };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteProductImageApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteProductImageApiEndPoint}?blob_id=${this.state.interiorDesignerProfile.attributes.product_photos[imageIndex].blob_id}&project_photos=true`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpDeleteMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteCurrentVideo = () => {
        this.state.interiorDesignerProfile.attributes.designer_videos?.filter((obj: string) => obj === this.state.currentSlide);
    }

    closeModal = () => {
        this.setState({
            isEditing: false,
            editType: null,
            profileImagePreview: "",
            bannerImagePreview: '',
            newBannerImage: null,
            newProfileImage: null
        });
    }

    handleSave = async (event: any) => {
        event.preventDefault();
        this.setState({ isEditing: false, editType: null, isLoading: true });
        const header = {
            "token": await getStorageData("TOKEN"),
        };
        const profile = {
            profile_picture: this.state.newProfileImage,
            full_name: this.state.updatedFullName,
            tag_line: this.state.updatedTagLine,
            about_us: this.state.updatedAboutUs,
            banner_image: this.state.newBannerImage,
        };
        const formDataEntries = Object.entries(profile);

        const interiorDesignerFormData = new FormData();
        formDataEntries.forEach(([key, value]: any) => {
            if (value && value.length !== 0) {
                interiorDesignerFormData.append(`profile[${key}]`, value);
            }
        })
        if (this.state.newProductImage) {
            for (const filePath of this.state.newProductImage) {
                interiorDesignerFormData.append('profile[project_photos][]', filePath);
            }
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateDesignerDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateUserDetailsApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            interiorDesignerFormData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpPatchMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSlide = (currentSlide: string) => {
        if(currentSlide){
            this.setState({
                currentSlide
            });
        }
    };

    getImage = () => {
        return this.state.profileImagePreview || this.state.interiorDesignerProfile?.attributes?.profile_picture;
    };

    gettagLine = () => {
        return this.state.interiorDesignerProfile.attributes.tag_line ? this.state.interiorDesignerProfile.attributes.tag_line : configJSON.addTagLine;
    };

    handleTagLine = () => {
        return this.state.interiorDesignerProfile.attributes.tag_line.length >= 20 ? this.state.interiorDesignerProfile.attributes.tag_line.substring(0, 20)+'...' : this.state.interiorDesignerProfile.attributes.tag_line;
    };

    getExistingImage = () => {
        return this.state.profileImagePreview ? this.state.profileImagePreview : this.state.interiorDesignerProfile?.attributes?.profile_picture;
    };

    getExistingAboutUsText = () => {
        return this.state.interiorDesignerProfile.attributes.about_us ? this.state.interiorDesignerProfile.attributes.about_us : configJSON.aboutUsText;
    };

    getBannerImage = () => {
        return this.state.bannerImagePreview ? this.state.bannerImagePreview : this.state.interiorDesignerProfile?.attributes?.banner_image;
    };
    
    // Customizable Area End
}
