export const designer = require("../assets/desighner.jpg");
export const sliderimage = require("../assets/sliderimage.jpg");
export const arrowleft = require("../assets/arrowleft.svg");
export const arrowright = require("../assets/arrowright.svg");
export const designerbanner = require("../assets/designerbanner.jpg");
export const anonymous = require("../assets/anonymous.png");
export const play = require("../assets/play.svg");
export const edit_brown = require("../assets/edit_brown.png")
export const edit_black = require("../assets/edit_black.png")
export const delete_outline = require("../assets/delete_outline.png")
export const button_backward = require("../assets/button_backward.svg");
export const button_forward = require("../assets/button_forward.svg");
