// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import { ToastContainer } from 'react-toastify';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import Loader from '../../components/src/Loader.web';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const InfoPage = React.lazy(() => import('../../blocks/info-page/src/InfoPageBlock'));
const AlertBlock = React.lazy(() => import('../../blocks/alert/src/AlertBlock.web'));
const OrderManagement = React.lazy(() => import('../../blocks/ordermanagement/src/OrderManagement'));
const ShoppingCartOrders = React.lazy(() => import('../../blocks/shoppingcart/src/ShoppingCartOrders'));
const AddShoppingCartOrderItem = React.lazy(() => import('../../blocks/shoppingcart/src/AddShoppingCartOrderItem'));
const NavigationMenu = React.lazy(() => import('../../blocks/navigationmenu/src/NavigationMenu'));
const EducationalUserProfile = React.lazy(() => import('../../blocks/educational-user-profile/src/EducationalUserProfile'));
const AdvancedSearch = React.lazy(() => import('../../blocks/advancedsearch/src/AdvancedSearch'));
const Emailnotifications2 = React.lazy(() => import('../../blocks/emailnotifications2/src/Emailnotifications2'));
const OTPInputAuth = React.lazy(() => import('../../blocks/otp-input-confirmation/src/OTPInputAuth'));
const Cfshopappinteg2 = React.lazy(() => import('../../blocks/cfshopappinteg2/src/Cfshopappinteg2'));
const Adminconsole2 = React.lazy(() => import('../../blocks/adminconsole2/src/Adminconsole2'));
const CountryCodeSelector = React.lazy(() => import('../../blocks/country-code-selector/src/CountryCodeSelector'));
const Catalogue = React.lazy(() => import('../../blocks/catalogue/src/Catalogue.web'));
const UserProfileBasicBlock = React.lazy(() => import('../../blocks/user-profile-basic/src/UserProfileBasicBlock'));
const Cfaccountingsoftware = React.lazy(() => import('../../blocks/cfaccountingsoftware/src/Cfaccountingsoftware'));
const Contactus = React.lazy(() => import('../../blocks/contactus/src/Contactus.web'));
const AddContactus = React.lazy(() => import('../../blocks/contactus/src/AddContactus'));
const Settings2 = React.lazy(() => import('../../blocks/settings2/src/Settings2'));
const Rolesandpermissions = React.lazy(() => import('../../blocks/rolesandpermissions/src/Rolesandpermissions'));
const SocialMediaAccountLoginScreen = React.lazy(() => import('../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen'));
const Cfklarnainteg2 = React.lazy(() => import('../../blocks/cfklarnainteg2/src/Cfklarnainteg2'));
const SocialMediaAccountRegistrationScreen = React.lazy(() => import('../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen'));
const EmailAccountLoginBlock = React.lazy(() => import('../../blocks/email-account-login/src/EmailAccountLoginBlock'));
const ForgotPassword = React.lazy(() => import('../../blocks/forgot-password/src/ForgotPassword.web'));
const ForgotPasswordOTP = React.lazy(() => import('../../blocks/forgot-password/src/ForgotPasswordOTP'));
const NewPassword = React.lazy(() => import('../../blocks/forgot-password/src/NewPassword'));
const StripePaymentView = React.lazy(() => import("../../blocks/stripepayments/src/StripePaymentView.web"));
const TermsConditions = React.lazy(() => import('../../blocks/termsconditions/src/TermsConditions'));
const TermsConditionsDetail = React.lazy(() => import('../../blocks/termsconditions/src/TermsConditionsDetail'));
const TermsConditionsUsers = React.lazy(() => import('../../blocks/termsconditions/src/TermsConditionsUsers'));
const CarouselDisplay = React.lazy(() => import('../../blocks/carouseldisplay/src/CarouselDisplay'));
const MobileAccountLoginBlock = React.lazy(() => import('../../blocks/mobile-account-login/src/MobileAccountLoginBlock'));
const LandingPage = React.lazy(() => import('../../blocks/landingpage/src/LandingPage.web'));
const EmailAccountRegistration = React.lazy(() => import('../../blocks/email-account-registration/src/EmailAccountRegistration.web'));
const Rolesandpermissions2 = React.lazy(() => import('../../blocks/rolesandpermissions2/src/Rolesandpermissions2'));
const Cfaccountingsoftware2 = React.lazy(() => import('../../blocks/cfaccountingsoftware2/src/Cfaccountingsoftware2'));
const Videos = React.lazy(() => import('../../blocks/videos/src/Videos'));
const BulkUploading = React.lazy(() => import('../../blocks/bulkuploading/src/BulkUploading'));
const PhotoLibrary = React.lazy(() => import('../../blocks/photolibrary/src/PhotoLibrary'));
const Cflogisticsintegration = React.lazy(() => import('../../blocks/cflogisticsintegration/src/Cflogisticsintegration'));
const ProductDescription = React.lazy(() => import("../../blocks/productdescription/src/ProductDescription.web"));
const CustomisableUserProfiles = React.lazy(() => import("../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web"));
const ViewUserProfiles = React.lazy(() => import("../../blocks/customisableuserprofiles/src/ViewUserProfiles.web"));
const PrivacyPolicy = React.lazy(() => import('../../blocks/termsconditions/src/PrivacyPolicy.web'));
const Cfwhatsappintegration330 = React.lazy(() => import("../../blocks/cfwhatsappintegration330/src/Cfwhatsappintegration330.web"));
const CareerPage = React.lazy(() => import("../../blocks/landingpage/src/CareerPage.web"))
const Chat = React.lazy(() => import('../../blocks/chat/src/Chat.web'));
const AboutUs = React.lazy(() => import("../../blocks/landingpage/src/AboutUs.web"));

import CategoriesSubcategories, {
  CategoriesSubcategoriesDetails
} from '../../blocks/categoriessubcategories/src/Categoriessubcategories.web';
import "react-toastify/dist/ReactToastify.css";
import EditInteriorDesignerProfiles from '../../blocks/customisableuserprofiles/src/EditInteriorDesignerProfiles.web';

const routeMap = {
  Cfaccountingsoftware2: {
    component: Cfaccountingsoftware2,
    path: "/Cfaccountingsoftware2"
  },
  ProductDescription: {
    component: ProductDescription,
    path: "/ProductDescription/:params"
  },
  Videos: {
    component: Videos,
    path: "/Videos"
  },
  BulkUploading: {
    component: BulkUploading,
    path: "/BulkUploading"
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: "/PhotoLibrary"
  },
  Cflogisticsintegration: {
    component: Cflogisticsintegration,
    path: "/Cflogisticsintegration"
  },

  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: "/Rolesandpermissions2"
  },

  OrderManagement: {
    component: OrderManagement,
    path: "/OrderManagement"
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: "/ShoppingCartOrders"
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: "/AddShoppingCartOrderItem"
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: "/NavigationMenu"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: "/Emailnotifications2"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  Cfshopappinteg2: {
    component: Cfshopappinteg2,
    path: "/Cfshopappinteg2"
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: "/Adminconsole2"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue/:catalogueId',
    exact: true
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  Cfaccountingsoftware: {
    component: Cfaccountingsoftware,
    path: "/Cfaccountingsoftware"
  },
  Contactus: {
    component: Contactus,
    path: "/Contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Settings2: {
    component: Settings2,
    path: "/Settings2",
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: "/Rolesandpermissions"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  Cfklarnainteg2: {
    component: Cfklarnainteg2,
    path: "/Cfklarnainteg2"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/login",
    wrapRoutes: "publicRoute"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/forgot-password",
    wrapRoutes: "publicRoute"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  StripePaymentView: {
    component: StripePaymentView,
    path: "/StripePaymentView",
  },
  TermsConditions: {
    component: TermsConditions,
    path: "/TermsConditions"
  },
  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: "/PrivacyPolicy"
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: "/TermsConditionsDetail"
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: "/TermsConditionsUsers"
  },
  CarouselDisplay: {
    component: CarouselDisplay,
    path: "/CarouselDisplay"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/signup",
    wrapRoutes: "publicRoute"
  },
  Categoriessubcategories: {
    component: CategoriesSubcategories,
    path: '/Catalogue/:catalogueId/CategoriesSubcategories/:categoryId',
    exact: true
  },

  CategoriesSubcategoriesDetails: {
    component: CategoriesSubcategoriesDetails,
    path:
      '/Catalogue/:catalogueId/CategoriesSubcategories/:categoryId/product/:productId',
    exact: true
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/interior-designers",
    exact: true
  },
  ViewUserProfiles: {
    component: ViewUserProfiles,
    path: "/interior-designers/:id",
    exact: true
  },
  Cfwhatsappintegration330: {
    component: Cfwhatsappintegration330,
    path: "/Cfwhatsappintegration330",
    exact: true
  },
  AboutUs: {
    component: AboutUs,
    path: "/AboutUs"
  },
  EditInteriorDesignerProfiles: {
    component: EditInteriorDesignerProfiles,
    path: "/EditInteriorDesignerProfiles"
  },
  EditInteriorDesignerProfiles: {
    component: EditInteriorDesignerProfiles,
    path: "/EditInteriorDesignerProfiles"
  },
  CareerPage: {
    component: CareerPage,
    path: "/career"
  },

  Chat: {
    component: Chat,
    path: "/Chat/:id"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh' }}>
        <React.Suspense fallback={<Loader loading={true} />}>
          {/* <TopNav /> */}
          <ToastContainer autoClose={3000} position="top-right" />
          {WebRoutesGenerator({ routeMap })}
        </React.Suspense>
        <ModalContainer />
      </View>
    );
  }
}

export default App;
