import React from "react";

// Customizable Area Start
import { styled, AppBar, Tabs, Tab, Box, Button, Modal, Typography } from "@material-ui/core";
import Slider from "react-slick";
import { banner } from "../../../components/src/assets";
import { arrowleft, arrowright, play, edit_black, edit_brown, delete_outline, anonymous } from "./assets";
import Videos from "../../videos/src/Videos.web";
import EditInteriorDesignerProfilesController, { Props } from "./EditInteriorDesignerProfilesController";
import Loader from "../../../components/src/Loader.web";
import ContactusView from "../../contactus/src/ContactusView.web";
import { configJSON } from "./CustomisableUserProfilesController";
// Customizable Area End

export default class EditInteriorDesignerProfiles extends EditInteriorDesignerProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    renderBanner = () => {
        if (this.state.interiorDesignerProfile.attributes.product_photos.some(photo => photo.url === this.state.currentSlide)) {
            return (
                <div>
                    <img className="banner_Img" src={this.state.currentSlide} alt="" />
                    <EditTxtBtn
                        className="deleteImgBtn"
                        style={{ top: '35px', right: '70px' }}
                        onClick={this.deleteCurrentImage}
                        data-testId="deleteCurrentImage"
                    >
                        <img src={delete_outline} alt="" className="sliderEdit" />
                        Delete Image
                    </EditTxtBtn>
                </div>
            )
        } else {
            return (<div>
                <Videos
                    id={this.props.id}
                    navigation={this.props.navigation}
                    videoUrl={this.state.currentSlide}
                />
                <EditTxtBtn
                    className="editBtn"
                    style={{ top: '35px', right: '70px' }}
                    onClick={this.deleteCurrentVideo}
                    data-testId="deleteCurrentVideo"
                >
                    <img src={delete_outline} className="sliderEdit" /> Delete Video
                </EditTxtBtn>
            </div>)
        }
    }

    renderForm() {
        const { editType, newHeading } = this.state;

        return (
            <div>
                {(editType === 'bannerImage' || editType === 'profileImage') && (
                    <div>
                        <ModalBox open={this.state.isEditing} onClose={this.closeModal}>
                            <ModalItemContainer>
                                <ModalInfoText>
                                    Please Upload new Image
                                </ModalInfoText>
                                <UploadBtnBox>
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none', }}
                                        id="uploadBanner"
                                        type="file"
                                        onChange={this.handleImageChange}
                                        data-testId="handleImageChange"
                                        multiple={false}
                                    />
                                    <label htmlFor="uploadBanner">
                                        <Button variant="contained" component="span" className="uploadBtn">
                                            Upload Image
                                        </Button>
                                    </label>
                                    <ModalButton disabled={!this.state.bannerImagePreview && !this.state.profileImagePreview} data-testid="saveBanner" onClick={this.handleSave} style={{ marginLeft: '20px' }}>Save</ModalButton>
                                </UploadBtnBox>
                            </ModalItemContainer>
                        </ModalBox>
                    </div>
                )}

                {editType === 'heading' && (
                    <div>
                        <ModalBox open={this.state.isEditing} onClose={this.closeModal}>
                            <ModalItemContainer>
                                <ModalInfoText>
                                    Please enter new tag line
                                </ModalInfoText>
                                <ModalContainer>
                                    <input
                                        type="text"
                                        value={this.state.updatedTagLine}
                                        data-testId="tagChange"
                                        onChange={(e) => this.handleTagChange(e.target.value)}
                                        className="frmControls"
                                    />
                                    <ModalButton disabled={!this.state.updatedTagLine} data-testid="removeProductId" onClick={this.handleSave}>Save</ModalButton>
                                </ModalContainer>
                            </ModalItemContainer>
                        </ModalBox>
                    </div>
                )}
                {editType === 'changeName' && (
                    <div>
                        <ModalBox open={this.state.isEditing} onClose={this.closeModal}>
                            <ModalItemContainer>
                                <ModalInfoText>
                                    Please enter updated Name and description
                                </ModalInfoText>
                                <ModalContainer>
                                    <input
                                        type="text"
                                        value={this.state.updatedFullName}
                                        onChange={(e) => this.handleNameChange(e.target.value)}
                                        className="frmControls"
                                        placeholder="Name"
                                        data-testId='nameUpdate'
                                    />
                                    <textarea
                                        rows={5}
                                        value={this.state.updatedAboutUs}
                                        onChange={(e) => this.handleAboutUsChange(e.target.value)}
                                        className="frmControls"
                                        placeholder="Description"
                                        data-testId='nameDescription'
                                    />
                                    <ModalButton
                                        data-testid="removeProductId"
                                        onClick={this.handleSave}
                                        data-testId='saveData'
                                        disabled={!this.state.updatedFullName || !this.state.updatedAboutUs}
                                    >
                                        Save
                                    </ModalButton>
                                </ModalContainer>
                            </ModalItemContainer>
                        </ModalBox>
                    </div>
                )}
            </div>
        );
    }
    // Customizable Area End

    render() {
        // Customizable Area Start

        let settings = {
            infinite: false,
            dots: false,
            slidesToShow: 10,
            speed: 500,
            initialSlide: 0,
            slidesToScroll: 1,
            autoplay: false,
            adaptiveHeight: true,
            autoplaySpeed: 2000,
            nav: true,
            centerMode: false,
            nextArrow: <img src={arrowright} alt="next_slide_btn" style={{ width: "60px", height: "60px" }} />,
            prevArrow: <img src={arrowleft} alt="prev_slide_btn" style={{ width: "100px", height: "100px" }} />,
            responsive: [
                {
                    breakpoint: 1919,
                    settings: {
                        slidesToScroll: 1,
                        initialSlide: 0,
                        slidesToShow: 13,
                    }
                },
                {
                    breakpoint: 1499,
                    settings: {
                        initialSlide: 0,
                        slidesToScroll: 1,
                        slidesToShow: 10,
                    }
                },
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToScroll: 1,
                        initialSlide: 0,
                        slidesToShow: 9,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToScroll: 1,
                        initialSlide: 0,
                        slidesToShow: 9,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToScroll: 1,
                        initialSlide: 0,
                        slidesToShow: 12,
                    }
                },
                {
                    breakpoint: 850,
                    settings: {
                        slidesToScroll: 1,
                        initialSlide: 0,
                        slidesToShow: 8,
                    }
                },
                {
                    breakpoint: 780,
                    settings: {
                        slidesToScroll: 1,
                        initialSlide: 0,
                        slidesToShow: 6,
                    }
                },
                {
                    breakpoint: 550,
                    settings: {
                        slidesToScroll: 1,
                        initialSlide: 0,
                        slidesToShow: 4,
                    }
                }
            ]
        };
        return (
            this.state.interiorDesignerProfile &&
            <>
                <BannerSections id="content">
                    <Loader loading={this.state.isLoading} />
                    <ContactusView id={this.props.id} navigation={this.props.navigation} />
                    <Box className="container-larges">
                        <Box className="banner-wraps">
                            <Box className="banner-imgs">
                                <img className="interior_hero_Img" src={this.getBannerImage() ? this.getBannerImage() : banner} alt="" />
                            </Box>
                            <Box className="editBannerImg">
                                <EditBannerBtn
                                    className="editBtn"
                                    data-testId="editBannerImg"
                                    style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
                                    onClick={() => this.handleEdit('bannerImage')}
                                >
                                    <img src={edit_brown} /> {configJSON.editImage}
                                </EditBannerBtn>
                            </Box>
                        </Box>
                    </Box>
                    <Box className="back-arrow-des" id="backErrow">
                        <img src={arrowleft} alt="goback" data-test-id="go_back" onClick={() => this.handleGoBacks()} />
                    </Box>
                    <Box className="tag-line" data-test-id="tagLine">
                    <Box className="tag_line_Box">
                        <Typography variant="h3" title={this.state.interiorDesignerProfile.attributes.tag_line} className="tag_line_content" component="p">
                            {this.gettagLine()}
                        </Typography>
                        <EditIconBtn
                            className="editIconBtn"
                            data-testId="editHeading"
                            onClick={() => this.handleEdit('heading')}
                        >
                            <img src={edit_black} />
                        </EditIconBtn>
                    </Box>
                    </Box>
                </BannerSections>
                <CustomSections>
                    <Box className="wrapper">
                        <Box className="content-right-mobile">
                            <Box className="profile_image">
                                {
                                this.getImage() ?
                                <img src={this.getExistingImage()} alt="profile-img" className="profile_picture" />
                                :
                                <img src={anonymous} alt="profileImg" className="profile_picture"/>
                                }
                                <EditIconBtn
                                    data-testId="editProfileImage"
                                    className='editIcon'
                                    onClick={() => this.handleEdit('profileImage')}
                                >
                                    <img src={edit_black} />
                                </EditIconBtn>
                            </Box>
                        </Box>
                        <Box className="content-left" data-test-id="contentLeft">
                            <h3>{this.state.interiorDesignerProfile.attributes.full_name}</h3>
                            <Box className="about-details">
                                <p>{this.getExistingAboutUsText()}</p>
                            </Box>
                            <EditTxtBtn
                                style={{ position: 'static', background: "#A27D4D", color: "#fff", width: "156px" }}
                                onClick={() => this.handleEdit('changeName')}
                                data-testId="changeName"
                            >
                                Edit Details
                            </EditTxtBtn>
                        </Box>
                        <Box className="content-right">
                            <Box className="profile_image">
                                {
                                this.getImage() ?
                                <img src={this.getExistingImage()} alt="profile-img" className="profile_picture" />
                                :
                                <img src={anonymous} alt="profileImg" className="profile_picture"/>
                                }
                                <EditIconBtn
                                    className='editIcon'
                                    onClick={() => this.handleEdit('profileImage')}
                                    data-testId='profileImage'
                                >
                                    <img src={edit_black} />
                                </EditIconBtn>
                            </Box>
                        </Box>
                    </Box>
                </CustomSections>
                {this.state.interiorDesignerProfile.attributes.product_photos &&
                    <BannerSections>
                        <Box className="container-larges">
                            <Box className="banner-wraps">
                                <Box className="banner-imgs">
                                    {this.state.currentSlide && this.renderBanner()}
                                </Box>
                            </Box>
                        </Box>
                    </BannerSections>}
                {this.state.interiorDesignerProfile.attributes.product_photos &&
                    this.state.interiorDesignerProfile.attributes.product_photos.length > 0 &&
                    <SliderSections>
                        <CustomSliders {...settings} data-test-id="custom_slider">
                            {
                                this.state.interiorDesignerProfile.attributes.product_photos.map((image, index) => (
                                    <img src={image.url} alt="slider-image" className={image.url === this.state.currentSlide ? "selected slider-image" : "slider-image"} key={index} data-test-id="slider-image" onClick={() => this.selectedHeroImage(image.url)} />
                                ))}
                            {
                                this.state.interiorDesignerProfile.attributes.designer_videos &&
                                this.state.interiorDesignerProfile.attributes.designer_videos.map((item, index) => (
                                    <div key={index} className={item === this.state.currentSlide ? "video-tag-wrapper selected" : "video-tag-wrapper"} onClick={() => this.selectedHeroImage(item)} data-test-id="slider-video">
                                        <video width="120" height="120" poster={this.state.interiorDesignerProfile.attributes.product_photos[index].url}>
                                            <source src={item} type="video/mp4" />
                                        </video>
                                        <img src={play} className="play-btn" />
                                        <div className="video-overlay" />
                                    </div>
                                ))
                            }
                        </CustomSliders>
                    </SliderSections>
                }
                <UploadBtnBox>
                    <input
                        accept="image/*"
                        style={{ display: 'none', }}
                        id="uploadSliderImg"
                        type="file"
                        onChange={this.uploadProductImage}
                        data-testId='uploadProductImage'
                        multiple={true}
                    />
                    <label htmlFor="uploadSliderImg">
                        <Button variant="contained" component="span" className="uploadBtn" data-test-id="uploadBtn">
                            {configJSON.uploadImage}
                        </Button>
                    </label>
                </UploadBtnBox>

                {this.state.isEditing && this.renderForm()}

            </>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const BannerSections = styled("section")({
    position: "relative",
    "& .container-larges": {
        width: "100%",
        maxWidth: "100%",
    },
    "& .banner-wraps": {},
    "& .banner-imgs .banner_Img": {
        height: "auto",
        width: "100%",
        maxHeight: "560px",
        minHeight: "360px",
        padding: "0px 10px",
        "@media(max-width:1024px)": {
            maxHeight: "550px"
        },
        "@media(max-width:950px)": {
            maxHeight: "520px"
        },
        "@media(max-width:850px)": {
            maxHeight: "490px"
        },
        "@media(max-width:700px)": {
            minHeight: "370px"
        },
        "@media(max-width:600px)": {
            minHeight: "355px"
        },
        "@media(max-width:550px)": {
            height: "231px"
        },
    },
    "& .banner-imgs .interior_hero_Img": {
        height: "auto",
        width: "100%",
        maxHeight: "560px",
        minHeight: "360px",
        padding: "0px 10px",
        "@media(max-width:1024px)": {
            maxHeight: "550px"
        },
        "@media(max-width:950px)": {
            maxHeight: "520px"
        },
        "@media(max-width:850px)": {
            maxHeight: "490px"
        },
        "@media(max-width:700px)": {
            minHeight: "370px"
        },
        "@media(max-width:600px)": {
            minHeight: "355px"
        },
        "@media(max-width:550px)": {
            height: "231px",
            padding: "0px",
        },
    },
    "& .back-arrow-des": {
        top: "98px",
        position: "absolute",
        cursor: "pointer",
        left: "30px",
        "@media(max-width: 550px)": {
            display: "none"
        }
    },
    "& .back-arrow-des img": {
        height: "25px",
        width: "25px",
    },
    "& .tag-line": {
        position: "absolute",
        bottom: "70px",
        left: "150px",
        display: "flex",
        flexWrap: "wrap",
        "@media(max-width: 768px)": {
            left: "50px",
        }
    },
    "& .tag_line_Box": {
        position: "absolute",
        background: "#F5F4F2",
        width: "300px",
        border: "2.46px solid #FFFFFF",
        borderRadius: "4.92px",
        padding: "30px",
        paddingBottom: "0px",
        "@media(max-width: 768px)": {
            width: "250px",
        },
        "@media(max-width: 550px)": {
            width: "218px",
        },
        "& .editIconBtn": {
            position: "relative",
            bottom: '-15px',
            right: '-245px',
            "@media(max-width: 768px)": {
                right: '-195px'
            },
            "@media(max-width: 550px)": {
                bottom: '-10px',
                right: '-170px'
            },
        }
    },
    "& .tag_line_content": {
        fontSize: "22px !important",
        color: "var(--black)",
        fontWeight: 300,
        fontStyle: "italic !important",
        fontFamily: 'Raleway',
        lineHeight: '39.39px !important',
        textTransform: "capitalize",
        display: "-webkit-box",
        "-webkit-line-clamp": "2",
        "-webkit-box-orient": "vertical",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
    "@media (max-width:1200px)": {
        "& .tag-line": {
            left: "100px",
            width: "400px",
        },
    },
    "@media (max-width:1024px)": {
        "& .tag-line": {
            left: "90px",
            width: "35%"
        },
    },
    "& .sliderText": {
        fontFamily: "Raleway",
        fontSize: "22px",
        fontWeight: 700,
        lineHeight: "55.39px",
        textAlign: "center",
        color: "rgba(255, 255, 255, 1)"
    },
    "& .sliderTextContainer": {
        position: "absolute",
        left: "40%",
        top: "42%",
        "@media (max-width:1024px)": {
            left: "33%"
        },
        "@media (max-width:810px)": {
            left: "30%"
        },
        "@media (max-width:768px)": {
            left: "28%"
        },
        "@media (max-width:550px)": {
            left: "30%"
        },
        "@media (max-width:428px)": {
            left: "23%"
        },
        "@media (max-width:375px)": {
            left: "20%"
        }

    },
    "& .deleteImgBtn": {
        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
        border: "1px solid #25272912",
        width: "155px",
        height: "40px",
        top: "30px !important",
        right: "35px !important",
        "@media (max-width:550px)": {
            width: "155px",
            height: "40px",
            top: "20px !important",
            right: "25px !important"
        },
    }
});

const CustomSections = styled("section")({
    width: "100%",
    margin: "200px auto 50px",
    maxWidth: "90%",
    "@media (max-width:550px)": {
        margin: "130px auto 50px",
    },
    "& .wrapper": {
        flexWrap: "wrap",
        display: "flex",
        maxWidth: "1500px",
        width: "auto",
        margin: "auto",
        "@media(max-width: 850px)": {
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "cneter"
        }
    },
    "& .content-left": {
        flex: "0 0 60%",
        "@media(max-width: 1160px)": {
            flex: "0 0 50%",
        },
        "@media(max-width: 850px)": {
            marginTop: "1%"
        }
    },
    "& .content-left h3": {
        fontWeight: 600,
        fontSize: '22px',
        lineHeight: '34.68px',
        color: "#252729CC",
        margin: '20px',
        "@media(max-width: 550px)": {
            marginLeft: '0px'
        },
    },
    "& .about-details": {
        marginLeft: '20px',
        height: "auto",
        minHeight: "250px",
        "@media (max-width:550px)": {
            marginLeft: '0px',
            marginBottom: '20px',
            minHeight: 'auto',
        }
    },
    "& .about-details p": {
        fontWeight: 500,
        fontSize: "16px",
        color: "#25272966",
        lineHeight: '26px',
    },
    "& .personal-details-wrapper": {
        height: "auto",
        minHeight: "400px"
    },
    "& .personal-details": {
        flexDirection: "column",
        display: "flex",
        fontSize: "22px",
        color: "rgba(37, 39, 41, 0.40)",
        lineHeight: "32px",
        fontWeight: 500,
        margin: "20px 0"
    },
    "& .content-right": {
        display: "flex",
        flex: "0 0 40%",
        justifyContent: "end",
        alignItems: "center",
        "@media(max-width: 2000px)": {
            justifyContent: "start"
        },
        "@media(max-width: 850px)": {
            justifyContent: "center"
        },
        "@media(max-width:550px)": {
            display: 'none'
        }
    },
    "& .content-right-mobile": {
        display: "flex",
        flex: "0 0 40%",
        justifyContent: "end",
        alignItems: "center",
        "@media(max-width: 850px)": {
            justifyContent: "start"
        },
        "@media(min-width:551px)": {
            display: 'none'
        }
    },
    "& .profile_image .profile_picture": {
        height: "360px",
        width: "270px",
        border: "6.15px solid #E8E8E8",
        objectFit: "cover",
        position: "relative",

        "@media (max-width: 850px)": {
            height: "460px",
            width: "90%"
        },
        "@media (max-width: 730px)": {
            width: "94%",
            height: "416px"
        },
        "@media (max-width: 650px)": {
            width: "95%",
            height: "400px"
        },
        "@media (max-width: 680px)": {
            width: "95%",
            height: "416px"
        },
        "@media (max-width: 600px)": {
            width: "95%",
            height: "350px"
        },
        "@media (max-width: 550px)": {
            height: "230px",
            width: "173px",
            border: '2px solid #E8E8E8'
        },
    },
    "& .profile_image .editIcon": {
        bottom: '0px',
        left: '-287px',
        "@media (max-width: 1520px)": {
            bottom: "30px",
            left: "-15px"
        },
        "@media (max-width: 850px)": {
            bottom: "0px",
            left: "-450px"
        },
        "@media (max-width: 815px)": {
            bottom: "0px",
            left: "-435px"
        },
        "@media (max-width: 768px)": {
            bottom: "0px",
            left: "-418px"
        },
        "@media (max-width: 730px)": {
            bottom: "20px",
            left: "-10px"
        },
        "@media (max-width: 550px)": {
            bottom: '0px',
            left: "-15px"
        }
    }
})

const AppBarTabs = styled(AppBar)({
    boxShadow: "none",
    textTransform: "lowercase"
});

const Tbs = styled(Tabs)({
    background: "white",
    color: "black",
    overflow: "unset",
    "& .MuiTabs-fixed span.MuiTabs-indicator": {
        backgroundColor: "var(--black)!important"
    }
});

const CustomTabs = styled(Tab)({
    textTransform: "capitalize",
    "& .MuiTab-wrapper": {
        color: "var(--shark)",
        fontSize: 16,
        fontFamily: "Raleway",
        fontWeight: "500",
    }
});

const SliderSections = styled("section")({
    width: "100%",
    maxWidth: "90%",
    margin: "40px auto 30px auto"
})

const CustomSliders = styled(Slider)({
    height: "auto",
    "@media (max-width: 550px)": {
        width: "90%",
        marginLeft: "20px"
    },
    "& .slick-list": {
        height: "auto !important"
    },
    "& .slick-track": {
        width: '100% !important',
        display: "flex"
    },
    "& .slick-track .slick-slide": {
        textAlign: "center",
        paddingRight: '10px',
        width: 'auto !important',
    },
    "& img": {
        height: "25px",
        width: "25px"
    },
    "& .slick-prev": {
        top: "30px",
        left: "-50px",
        "@media (max-width:550px)": {
            top: "30px",
            left: "-30px",
        }
    },
    "& .slick-next": {
        top: "30px",
        right: "-50px",
        "@media (max-width:810px)": {
            right: "-40px"
        },
        "@media (max-width:800px)": {
            right: "-35px"
        },
        "@media (max-width:780px)": {
            right: "-20px",
        },
        "@media (max-width:550px)": {
            top: "30px",
            right: "-35px",
        },
        "@media (max-width:400px)": {
            top: "30px",
            right: "-30px"
        }
    },
    "& .slick-list .slick-track .slick-slide .slider-image": {
        width: "100%",
        height: "auto",
        borderRadius: "2.46px",
        border: "1.23px solid #FFF",
        boxShadow: '0px 2.46px 2.46px 0px #00000040',
        minWidth: "62px",
        maxWidth: "62px",
        minHeight: "62px",
        maxHeight: "62px",
        cursor: "pointer",
    },
    "& .video-tag-wrapper": {
        position: "relative",
        width: "100%",
        height: "auto",
        borderRadius: "2.46px",
        border: "1.23px solid #FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        minWidth: "62px",
        maxWidth: "62px",
        minHeight: "62px",
        maxHeight: "62px",
        cursor: "pointer",
        overflow: 'hidden',
    },
    "& .video-tag-wrapper video": {
        width: "100%",
        objectFit: "cover",
    },
    "& .video-overlay": {
        top: 0,
        position: "absolute",
        right: 0,
        left: 0,
        background: "#000",
        bottom: 0,
        borderRadius: "4px",
        opacity: 0.2,
        border: "2px solid #FFF",
        boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
        pointerEvents: "none",
    },
    "& .play-btn": {
        top: "50%",
        position: "absolute",
        maxWidth: "35px",
        left: " 50%",
        boxShadow: "none",
        minWidth: "35px",
        maxHeight: "none",
        border: 0,
        transform: "translate(-50%, -50%)",
        minHeight: "auto",
    },
    "& .selected": {
        border: "5px solid var(--driftwood) !important"
    }
});
const EditIconBtn = styled('button')({
    width: '40.62px',
    height: '40.62px',
    border: 'none',
    background: '#fff',
    position: 'relative',
    borderRadius: '50%',
    boxShadow: `0px 6.09px 15.23px -3.05px #00000026`,
    textAlign: 'center',
    cursor: 'pointer',
    "& img": {
        width: '24.62px',
        height: '24.62px'
    },
    "@media(max-width: 550px)": {
        width: "24px",
        height: "24px",
        "& img": {
            width: '15px',
            height: '15px'
        },
    }
});
const EditBannerBtn = styled('button')({
    position: 'absolute',
    background: '#fff',
    border: 'none',
    display: 'inline-block',
    width: "170px",
    height: '35px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '50px',
    color: '#A27D4D',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Raleway',
    top: '35px',
    right: '70px',
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
    "@media (max-width:550px)": {
        height: "40px",
        right: '50px',
        fontSize: "16px"
    },
    "& img": {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: '4px',
        width: '16px',
        height: '16px'
    },
});

const EditTxtBtn = styled('button')({
    position: 'absolute',
    background: '#fff',
    border: 'none',
    display: 'inline-block',
    width: '150px',
    height: '35px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '50px',
    color: '#A27D4D',
    fontSize: '16px',
    fontWeight: 600,
    fontFamily: 'Raleway',
    top: '35px',
    right: '70px',
    "@media (max-width:850px)": {
        width: "150px",
        height: "50px",
        fontSize: "16px"
    },
    "@media (max-width:550px)": {
        width: "150px",
        height: "40px",
        right: '50px',
        fontSize: "16px"
    },
    "@media(max-width:450px)": {
        width: "135px",
        right: "40px",
        fontSize: "14px",
    },
    "@media(max-width:400px)": {
        width: "140px",
        height: "38px",
        fontSize: "13px",
        right: "40px"
    },

    "& img": {
        display: 'inline-block',
        height: '16px',
        width: '16px',
        position: 'relative',
        top: '2px'
    },
    "& .sliderEdit": {
        width: '16px',
        height: '16px',
        maxHeight: 'inherit',
        minHeight: 'inherit',
        marginRight: "4px"
        },
});
const UploadBtnBox = styled('div')({
    textAlign: 'center',
    "@media (max-width: 550px)": {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        alignItems: 'center',
        paddingBottom: "50px"
    },
    "& span.uploadBtn": {
        background: '#A27D4D',
        display: 'inline-block',
        border: 'none',
        height: '35px',
        width: "240px",
        cursor: 'pointer',
        textAlign: 'center',
        color: '#fff',
        borderRadius: '35px',
        fontWeight: 600,
        fontSize: '16px',
        fontFamily: 'Raleway',
        lineHeight: "25px",
        textTransform: 'capitalize',
        boxShadow: 'none',
        "@media (max-width:550px)": {
            width: "180px",
            height: "40px",
            fontSize: "16px",
            lineHeight: '28px'
        },
    },
    "& .uploadBtn": {
        width: "96.02px",
        height: "34.25px",
        borderRadius: "30.58px 0px 0px 0px",
        opacity: "0px"
    }
})
const ModalBox = styled(Modal)({
    justifyContent: "center",
    display: "flex",
    height: "100%",
    alignItems: "center",
    boxSizing: "border-box",
    width: "100%",
});

const ModalItemContainer = styled(Box)({
    flexDirection: "column",
    display: "flex",
    backgroundColor: "#ffffff",
    padding: "35px",
    boxShadow: "1px 1px 5px 0px #00000040",
    borderRadius: "15px",
    width: "600px",
    justifyContent: "space-around",
    position: "relative",
    height: "auto",
    border: '1px solid #25272966',
    outline: "none",

    "@media (max-width:550px)": {
        width: "100%",
        fontSize: '16px'
    },
});

const ModalContainer = styled(Box)({
    textAlign: 'center',

    "& .frmControls": {
        width: '100% !important',
        display: 'block',
        border: '1px solid #25272966',
        height: '56px',
        padding: '10px 15px',
        borderRadius: '5px',
        color: '#252729',
        fontSize: '16px',
        marginBottom: '30px',
        fontFamily: 'Raleway',
    },
});

const ModalInfoText = styled(Typography)({
    color: "rgba(37, 39, 41, 0.60)",
    textAlign: "center",
    fontSize: "22px",
    fontWeight: 600,
    margin: "0px 0 40px 0",
    fontFamily: "Raleway",
    "@media (max-width:550px)": {
        fontSize: '16px'
    }
});

const ModalButton = styled("button")({
    color: "#FFF",
    background: "var(--driftwood)",
    border: "aliceblue",
    borderRadius: "50px",
    width: "150px",
    fontWeight: 600,
    cursor: "pointer",
    fontFamily: 'Raleway',
    fontSize: '16px',
    height: '35px',
    "@media (max-width:550px)": {
        marginLeft: "0px !important",
        width: '180px',
        height: '40px'
    }
});
// Customizable Area End
