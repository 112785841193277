Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.searchApiContentType = "application/json";
exports.editApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint =
  "bx_block_profile/profile/custom_user_profile_fields";
exports.httpDeleteMethod = "DELETE";
exports.getInteriorDesignersApiEndPoint = "account_block/interior_designers";
exports.getInteriorDesignerApiEndPoint = "account_block/interior_designers";
exports.getDesignerDescriptionApiEndPoint = "bx_block_navmenu/navigation_menu";
exports.exampleVideoURL = ["https://storage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"];
exports.updateUserDetailsApiEndPoint = "bx_block_profile/profiles/update_user_profile";
exports.deleteProductImageApiEndPoint = "bx_block_profile/profiles/delete_interiror_designer_image";
exports.bannerText = "Interior Design Studio";
exports.save = "Save";
exports.editImage = "Edit hero Image";
exports.uploadImage = "Upload portfolio images";
exports.addTagLine = "Add a tagline";
exports.aboutUsText = "Tell us about yourself";
exports.locationText = "Enter your location here";
// Customizable Area End
