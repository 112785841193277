Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";

exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";

exports.filtersApiEndPoint="filter_attributes";
exports.filtersApiContentType="application/json"

exports.errorTitle = "Error"
exports.errorAllFieldsAreMandatory = "Please enter a category first"
exports.errorCategory = "Please select a category first"

exports.subCategoryListApiEndPoint1 = "bx_block_categories/categories";
exports.subCategoryListApiEndPoint2 = "get_subcategory";
exports.subCategoryListApiContentType = "application/json";

exports.productListApiEndPoint1 = "bx_block_categories/sub_categories";
exports.productListApiEndPoint2 = "get_products";
exports.productListApiContentType = "application/json";
exports.postFilterApiEndPoint="filter_products";
// Customizable Area End