// Customizable Area Start
import React, { useEffect, useState } from "react";
import { styled, Box, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";

interface ICatalogueSubcategoriesCardProps {
  image: string;
  name: string;
  description:string;
  id: string;
  categoryId: string;
  catalogueId: string;
}

const CatalogueSubcategoriesCard: React.FC<
  ICatalogueSubcategoriesCardProps
> = ({ image, name, id, categoryId, catalogueId,description }) => {
  const [imageUrl, setImageUrl] = useState(image);

  useEffect(() => {
    if (!image) {
      setImageUrl(
        "https://housing.com/news/wp-content/uploads/2023/01/a-4.jpg"
      );
      return;
    }
    setImageUrl(image);
  }, [image]);

  const handleImageError = () => {
    setImageUrl("https://housing.com/news/wp-content/uploads/2023/01/a-4.jpg");
  };

  return (
    <CatalogueSubcategoriesCardContainer
      to={
        "/Catalogue/" +
        catalogueId +
        "/CategoriesSubcategories/" +
        categoryId +
        "/product/" +
        id
      }
    >
      <img
        src={imageUrl}
        className="catalogue-subcategories-image"
        onError={handleImageError}
        alt="sub category"
      />
      <Box className="catalogue-subcategories-name-container"
      >
        <Typography className="catalogue-subcategories-name" title={name}>
          {name}
        </Typography>
        <Typography className="catalogue-subcategories-description" title={description}>
          {description}
        </Typography>
      </Box>
    </CatalogueSubcategoriesCardContainer>
  );
};

const CatalogueSubcategoriesCardContainer = styled(Link)(({ theme }) => ({
  borderRadius: "8px",
  border: "1px solid rgba(37, 39, 41, 0.20)",
  background: "#FFF",
  height: "100%",
  cursor: "pointer",
  display: "block",
  textDecoration: "unset",
  "&:hover": {
    textDecoration: "unset",
  },
  "& .catalogue-subcategories-image": {
    borderRadius: "8px 8px 0px 0px",
    width: "100%",
    height: "400px",
    objectFit: "cover",
  },
  "& .catalogue-subcategories-name-container": {
    padding: "17px 20px 40px 20px",
  },
  "& .catalogue-subcategories-name": {
    color: "rgba(37, 39, 41, 0.70)",
    fontFamily: "Raleway",
    fontSize: "22px",
    marginLeft:"10px",
    marginTop:"10px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "capitalize",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  "& .catalogue-subcategories-description":{
    color: "rgba(37, 39, 41, 0.70)",
    fontFamily: "Raleway",
    fontSize: "16px",
    marginLeft:"10px",
    marginTop:"10px",
    fontStyle: "normal",
    lineHeight: "28.18px",
    textTransform: "capitalize",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
  },
  [theme.breakpoints.down("md")]: {
    "& .catalogue-subcategories-image": {
      height: "375px",
    },
    "& .catalogue-subcategories-name-container": {
      padding: "17px 20px 60px 20px",
    },
    "& .catalogue-subcategories-name": {
      fontSize: "22px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .catalogue-subcategories-image": {
      height: "350px",
    },
    "& .catalogue-subcategories-name": {
      fontSize: "22px",
    },
    "& .catalogue-subcategories-name-container": {
      padding: "17px 20px 55px 20px",
    },
  },
  [theme.breakpoints.down("xs")]: {
    "& .catalogue-subcategories-name": {
      fontSize: "22px",
    },
    "& .catalogue-subcategories-image": {
      height: "300px",
    },
    "& .catalogue-subcategories-name-container": {
      padding: "17px 20px 45px 20px",
    },
  },
}));

export default CatalogueSubcategoriesCard;

// Customizable Area End
