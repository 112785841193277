import React from "react";
// Customizable Area Start
import { styled, Container, Grid, Box, Button, Drawer } from "@material-ui/core";
import BackTitleDescription from "../../../components/src/BackTitleDescription.web";
import CatalogueSubcategoriesCard from "../../../components/src/CatalogueSubcategoriesCard.web";
import SubCategoryCard from "../../../components/src/SubCategoryCard.web";
import Loader from "../../../components/src/Loader.web";
import NotFoundData from "../../../components/src/NotFoundData.web";
import CategoriessubcategoriesController, {
  Props,
} from "./CategoriessubcategoriesController";
import CategoryFilter from "../../../components/src/CategoryFilter.web";
import ContactusView from "../../contactus/src/ContactusView.web";
import { Link } from "react-router-dom";
import { Logo } from "./assets";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

export default class CategoriesSubcategories extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const totalCategoryItems = this.state.subCategoryList.length;
    const categoriesToShow = 3;
    return (
      <>
        <ContactusView id={undefined} navigation={undefined} />
        <CategoriesStyledContainer maxWidth="xl">
          <Loader loading={this.state.isLoading} />
          <BackTitleDescription
            data-test-id="category-back-button"
            description={this.state.description}
            onClick={() => {
              this.props.navigation.goBack();
            }}
            title={this.state.title}
          />
          {this.state.subCategoryList.length === 0 && (
            <NotFoundData message="No sub category list" />
          )}
          <div className="desktop-subcategories">
            {this.state.subCategoryList.length > 0 && (
              <Grid className="grid-container" container spacing={6}>
                {this.state.subCategoryList.map((data) => (
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    key={data.attributes.name}
                  >
                    <CatalogueSubcategoriesCard
                      id={data.id}
                      categoryId={this.state.categoryId}
                      image={data.attributes.sub_cat_icon}
                      description={data.attributes.description}
                      name={data.attributes.name}
                      catalogueId={this.state.catalogueId}
                    />
                  </Grid>
                ))}
              </Grid>
            )}
          </div>

          <div className="responsive-subcategories">
            {this.state.paginatedSubcategoies.length > 0 && (
              <Grid className="grid-container" container spacing={6}>
                {this.state.paginatedSubcategoies.map((data) => (
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    key={data.attributes.name}
                  >
                    <CatalogueSubcategoriesCard
                      id={data.id}
                      categoryId={this.state.categoryId}
                      image={data.attributes.sub_cat_icon}
                      description={data.attributes.description}
                      name={data.attributes.name}
                      catalogueId={this.state.catalogueId}
                    />
                  </Grid>
                ))}
              </Grid>
            )}

            {this.state.subCategoryList.length > 3 &&
              <div className="pagination-styles">
                <Pagination
                  count={Math.ceil(totalCategoryItems / categoriesToShow)}
                  page={this.state.currentPaginationNumber}
                  onChange={this.handleSubcategoryPaginationChange}
                  shape="rounded"
                  className="pagination_category"
                />
              </div>}
          </div>

        </CategoriesStyledContainer>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
export const CategoriesStyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: "20px",
  maxWidth: "1690px",
  margin: "80px auto",
  "& .grid-container": {
    width: "calc(100% + 120px)",
    margin: "-62px",
    marginTop: 5,
  },
  "& .pagination-styles": {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
    marginBottom: '30px'
  },
  "& .MuiGrid-spacing-xs-6 > .MuiGrid-item": {
    padding: "30px 60px",
  },
  [theme.breakpoints.down("lg")]: {
    "& .grid-container": {
      width: "calc(100% + 54px)",
      margin: "-64px -30px -24px",
      marginTop: 5,
    },
    "& .MuiGrid-spacing-xs-6 > .MuiGrid-item": {
      padding: "40px",
    },
  },
  [theme.breakpoints.down("md")]: {
    margin: "65px 0",
    "& .desktop-subcategories": {
      display: 'none',
    },
    "& .responsive-subcategories": {
      display: 'block',
    }
  },
  [theme.breakpoints.down("sm")]: {
    margin: "50px 0",
    "& .grid-container": {
      marginTop: 5,
      width: "calc(100% + 48px)",
      margin: "-24px",
    },
    "& .MuiGrid-spacing-xs-6 > .MuiGrid-item": {
      padding: "16px",
    },
    paddingTop: '0px'
  },
  [theme.breakpoints.down("xs")]: {
    margin: "0px 0",
    "& .grid-container": {
      margin: 0,
      width: "100%",
    },
    "& .MuiGrid-spacing-xs-6 > .MuiGrid-item": {
      padding: "16px 0",
    },
  },
  [theme.breakpoints.up("sm")]: {
    "& .desktop-subcategories": {
      display: 'block',
    },
    "& .responsive-subcategories": {
      display: 'none',
    }
  },
  "& .pagination_category" : {
    "& .MuiPaginationItem-icon" : {
      fontSize: "2.25rem !important",
      color: "#252729 !important"
     },
     "& .MuiPaginationItem-page" : {
       "&.Mui-selected" : {
       background: "none !important",
       color: "#252729 !important",
       fontSize: "24px",
       fontWeight: 600,
       fontFamily: "Poppins"
      },
      "&.Mui-disabled" : {
        opacity: 1
      },
     },
     "& .MuiPaginationItem-root" : {
       color: "#989898 !important",
       fontSize: "20px",
       fontWeight: 300,
       fontFamily: "Poppins"
    }
  }
}));

export const SubCategoriesStyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: "1405px",
  paddingTop: "20px",
  margin: "80px auto",
  "& .filters": {
    padding: "0 60px",
    width: "100%",
    display: "flex",
    flexWrap: "wrap"
  },
  "& .grid-container": {
    width: "calc(100% + 120px)",
    margin: "-60px",
    marginTop: 5,
  },
  "& .pagination_product": {
    marginTop: '25px',
  },
   "& .product_page": {
    marginTop: "35px"
  },
  "& .box-container": {
    margin: "30px -12px 0",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",

    "@media(max-width:1387px)": {
    },
  },
  "& .drawer_filter": {
    margin: '20px'
  },
  "& .grid-all-item-container": {
    padding: "0 60px",
  },
  "& .MuiGrid-spacing-xs-6 > .MuiGrid-item": {
    padding: "30px 60px",
  },
  [theme.breakpoints.down("md")]: {
    margin: "30px 0",
    "& .box-container": {
      marginTop: "60px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    margin: "50px 0",
    "& .grid-all-item-container": {
      padding: "0 45px",
    },
    "& .grid-container": {
      marginTop: 5,
      width: "calc(100% + 48px)",
      margin: "-24px",
    },
    "& .MuiGrid-spacing-xs-6 > .MuiGrid-item": {
      padding: "24px",
    },
    "& .box-container": {
      marginTop: "30px",
    },
    "& .product_page": {
      marginTop: "0px"
    },
  
  },
  [theme.breakpoints.down("xs")]: {
    margin: "30px 0",
    "& .grid-all-item-container": {
      padding: "0 10px",
    },
    "& .box-container": {
      marginTop: "25px",
    },
    "& .grid-container": {
      margin: 0,
      width: "100%",
    },
    "& .MuiGrid-spacing-xs-6 > .MuiGrid-item": {
      padding: "12px 0",
    },
  },
  "& .pagination_subcategory": {
    "& .MuiPaginationItem-icon" : {
      fontSize: "2.25rem !important",
      color: "#252729 !important"
     },
     "& .MuiPaginationItem-page" : {
       "&.Mui-selected" : {
       background: "none !important",
       color: "#252729 !important",
       fontSize: "24px",
       fontWeight: 600,
       fontFamily: "Poppins"
      },
      "&.Mui-disabled" : {
        opacity: 1
      },
     },
     "& .MuiPaginationItem-root" : {
       color: "#989898 !important",
       fontSize: "20px",
       fontWeight: 300,
       fontFamily: "Poppins"
     }
  }
}));
const HeaderLogo = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  flex: "0 0 200px",
  padding: '15px'
});
const HeaderImg = styled("img")({
  cursor: "pointer",
  width: '150px'
});
const FilterButton = styled(Button)({
  background: 'rgb(162, 125, 77)',
  border: '1px solid rgb(162, 125, 77)',
  color: 'white',
  marginLeft: '10px',
  padding: '3px 10px',
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(162, 125, 77)"
}

})

export class CategoriesSubcategoriesDetails extends CategoriessubcategoriesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    const isMobileView = window.matchMedia("(max-width: 550px)").matches;
    return (
      // Customizable Area Start
      <>
        <ContactusView id={undefined} navigation={undefined} />
        <SubCategoriesStyledContainer maxWidth="lg" id="content">
          <Loader loading={this.state.isProductsLoading} />
          <BackTitleDescription
            data-test-id="sub-category-back-button"
            description={this.state.description}
            onClick={() => {
              this.props.navigation.goBack();
            }}
            title={this.state.title}
          />
          <Box className="box-container">
          { isMobileView
            ? <FilterButton
                onClick={this.toggleDrawer}
                variant="text"
                data-test-id={`filterButtonToggle`}
              >
                Filter By +
              </FilterButton>
            :
            <div className="filters">{
            this.state.filtersList.map((filter: any, index: any) => (
              <CategoryFilter
                data-test-id={`chip$${index}`}
                filterList={this.state.filtersList}
                filterData={filter}
                index={index}
                key={"color" + index}
                chipClick={this.productFilterApiCall}
              />
            ))}
            </div>
            }
          </Box>
          <Box className="grid-all-item-container">
            {this.noProduct() && (
              <NotFoundData message="No product found" />
            )}
            {this.getProductList() && (<>
              <Grid className="grid-container" container spacing={6}>
                {this.state.productList !== null && this.state.productList.map((data) => (
                  <Grid
                    item
                    lg={4}
                    md={4}
                    sm={6}
                    xs={12}
                    key={data.attributes.name + data.id}
                  >
                    <SubCategoryCard
                      data-test-id="sub_category"
                      id={data.id}
                      image={data.attributes.product_photos?.[0]}
                      name={data.attributes.name}
                      type={data.attributes.details}
                      price={this.formatPrice(data.attributes.price)}
                      onClick={() => this.navigateToProductDescrption(data)}
                    />
                  </Grid>
                ))}
            </Grid> 
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              className="product_page"
            >
              <div className="pagination_product">
                <Pagination
                  data-test-id = "productPageBtnTestId"
                  count={this.state.totalProductPages}
                  page={this.state.currentProductPageNumber}
                  onChange={this.handleProductPagination}
                  shape="rounded"
                  className="pagination_subcategory"
                />
              </div>
            </Grid>       
            </>
            )}
          </Box>
        </SubCategoriesStyledContainer>
        <Drawer
            anchor={"left"}
            open={this.state.webDrawer}
            onClose={this.toggleDrawer}
            PaperProps={{ style: { width: '80%' } }}
          >
             <Box className="drawer_filter">
             <HeaderLogo>
                  <Link to="/">
                      <HeaderImg src={Logo} alt='header logo' />
                  </Link>
              </HeaderLogo>
              <hr />
              {this.state.filtersList.map((filter: any, index: any) => (
                <CategoryFilter
                  data-test-id={`chip$${index}`}
                  filterList={this.state.filtersList}
                  filterData={filter}
                  index={index}
                  key={"color" + index}
                  chipClick={this.productFilterApiCall}
                />
              ))}
          </Box>
          </Drawer>
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area End
