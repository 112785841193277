// Customizable Area Start
import React from "react";
import { styled, Box, Typography } from "@material-ui/core";

interface IBackTitleDescriptionProps {
  description: string;
  title: string;
  onClick: () => void;
}

const BackTitleDescription: React.FC<IBackTitleDescriptionProps> = ({
  description,
  title,
  onClick,
}) => {
  return (
    <BackTitleDescriptionContainer>
      <Box className="image-text-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          onClick={onClick}
          className="back-image"
        >
          <g clipPath="url(#clip0_2660_2987)">
            <path
              d="M47.5 27.4992H17.85L26.925 16.5992C27.3493 16.0887 27.5535 15.4305 27.4926 14.7694C27.4316 14.1083 27.1105 13.4986 26.6 13.0742C26.0895 12.6499 25.4313 12.4457 24.7702 12.5067C24.1091 12.5676 23.4993 12.8887 23.075 13.3992L10.575 28.3992C10.4909 28.5185 10.4157 28.6439 10.35 28.7742C10.35 28.8992 10.35 28.9742 10.175 29.0992C10.0617 29.3859 10.0024 29.691 10 29.9992C10.0024 30.3074 10.0617 30.6126 10.175 30.8992C10.175 31.0242 10.175 31.0992 10.35 31.2242C10.4157 31.3546 10.4909 31.4799 10.575 31.5992L23.075 46.5992C23.3101 46.8814 23.6044 47.1084 23.9371 47.2639C24.2698 47.4195 24.6327 47.4998 25 47.4992C25.5841 47.5004 26.1502 47.2969 26.6 46.9242C26.8531 46.7144 27.0624 46.4566 27.2158 46.1657C27.3692 45.8749 27.4637 45.5566 27.4938 45.2292C27.524 44.9017 27.4893 44.5715 27.3917 44.2575C27.2941 43.9435 27.1355 43.6518 26.925 43.3992L17.85 32.4992H47.5C48.163 32.4992 48.7989 32.2358 49.2678 31.767C49.7366 31.2981 50 30.6623 50 29.9992C50 29.3362 49.7366 28.7003 49.2678 28.2315C48.7989 27.7626 48.163 27.4992 47.5 27.4992Z"
              fill="#252729"
            />
          </g>
          <defs>
            <clipPath id="clip0_2660_2987">
              <rect width="60" height="60" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <Typography className="category-header" title={title}>
          {title}
        </Typography>
      </Box>
      {title === 'Shop by room' &&
        <DisplayAll>
          <Typography className="category-subheader" title={title}>
          </Typography>
        </DisplayAll>}
      <Box className="text-container">
        <Typography className="category-description" title={description}>{description}</Typography>
      </Box>
    </BackTitleDescriptionContainer>
  );
};

const BackTitleDescriptionContainer = styled(Box)(({ theme }) => ({
  "& .image-text-container": {
    display: "flex",
    alignItems: "center",
    paddingLeft: "60px",
    position: "relative",
    marginTop: "50px",
    "@media(max-width: 550px)": {
      marginTop: "10px",
      paddingLeft: "0px"
    }
  },
  "& .back-image": {
    height: "30px",
    width: "40px",
    position: "absolute",
    left: 0,
    top: 0,
    bottom: 0,
    margin: "auto 0",
    cursor: "pointer",
    "@media(max-width: 550px)": {
      marginTop: "0px !important",
      display: "none",
    }
  },
  "& .category-header": {
    color: "rgba(37, 39, 41, 0.80)",
    fontFamily: "Raleway",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "capitalize",
    breakWord: "break-word",
    "@media(max-width: 1024px)": {
      fontFamily: "Raleway",
      fontSize: "22px",
      fontWeight: 600,
      lineHeight: "25.83px",
      textAlign: "left",
      color: "rgba(37, 39, 41, 0.8)",
    },
    "@media(max-width: 550px)": {
      marginTop: "0px !important"
    }
  },
  "& .text-container": {
    paddingLeft: "60px",
    width: "100%"
  },
  "& .category-description": {
    color: "#9EA19A",
    fontFamily: "Raleway",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "28.18px",
    breakWord: "break-word",
    padding: "12px 0px 0px",
  },
  [theme.breakpoints.down("lg")]: {
    "& .category-description": {
      fontSize: "16px",
    },
    "& .text-container": {
      paddingLeft: "60px",
    },

    "& .category-header": {
      fontSize: "22px",
    },
    "& .category-subheader": {
      display: 'none'
    },
    "& .image-text-container": {
      paddingLeft: "60px",
    },
    "& .back-image": {
      height: "30px",
      width: "40px",
      left: "-6px",
    },
  },
  [theme.breakpoints.down("md")]: {
    "& .category-description": {
      fontSize: "16px",
    },
    "& .text-container": {
      paddingLeft: "60px",
    },

    "& .category-header": {
      fontSize: "22px",
    },
    "& .category-subheader": {
      display: 'none',
    },
    "& .image-text-container": {
      paddingLeft: "60px",
    },
    "& .back-image": {
      height: "30px",
      width: "40px",
      left: "-6px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .text-container": {
      paddingLeft: "50px",
    },
    "& .category-description": {
      fontSize: "16px",
    },
    "& .category-subheader": {
      display: 'block',
      fontSize: '18px',
      fontWeight: 'normal',
      color: "rgba(37, 39, 41, 0.8)"
    },
    "& .image-text-container": {
      paddingLeft: "50px",
      alignItems: 'flex-start',
      marginTop: '50px'
    },
    "& .back-image": {
      height: "30px",
      width: "40px",
      marginTop: "22px"
    },
    "& .category-header": {
      fontSize: "22px",
      marginTop: "25px"
    },
  },
  [theme.breakpoints.down("xs")]: {
    "& .text-container": {
      paddingLeft: "0px",
    },
    "& .category-description": {
      fontSize: "16px",
    },
    "& .image-text-container": {
      paddingLeft: "30px",
      marginTop: '60px'
    },
    "& .category-header": {
      fontSize: "16px",
      marginTop: "0px"
    },
    "& .category-subheader": {
      display: 'block',
      fontSize: '18px',
      fontWeight: 'normal',
      color: "rgba(37, 39, 41, 0.8)"
    },
    "& .back-image": {
      height: "30px",
      width: "40px",
      marginTop: "0px"
    },
  },
}));

const DisplayAll = styled(Box)(({ theme }) => ({
  marginTop: '16px',
  [theme.breakpoints.down("lg")]: {
    "& .category-subheader": {
      display: 'none'
    },
  },
  [theme.breakpoints.down("md")]: {

    "& .category-subheader": {
      display: 'none',
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .category-subheader": {
      dispay: 'block',
      fontSize: '18px',
      fontWeight: 'bold',
      color: "rgba(37, 39, 41, 0.8)"
    },
  },
  [theme.breakpoints.down("xs")]: {
    "& .category-subheader": {
      display: 'block',
      fontSize: '18px',
      fontWeight: 'bold',
      color: "rgba(37, 3s9, 41, 0.8)"
    },
  },
}));

export default BackTitleDescription;

// Customizable Area End
