import React from "react";
import { Modal, Fade, Backdrop, CircularProgress } from "@material-ui/core";

interface myProps {
  loading: boolean;
}

export default function Loader(props: myProps) {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      open={props.loading}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableAutoFocus={true}
    >
      <Fade in={props.loading} style={{ border: "none" }}>
        <div
          style={{
            backgroundColor: "transparent",
            outline: "none",
            borderRadius: 29,
            width: "auto",
            height: "auto",
          }}
        >
          <CircularProgress className="loader-white" value={10} size={60} style={{ color: "var(--driftwood)" }} />
        </div>
      </Fade>
    </Modal>
  );
}