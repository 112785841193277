// Customizable Area Start
import React, { useEffect, useState } from "react";
import { styled, Box, Typography } from "@material-ui/core";

interface SubCategoryCardProps {
  image: string;
  name: string;
  type: string;
  price: number;
  id: string;
  onClick?: () => void;
}

const SubCategoryCard: React.FC<SubCategoryCardProps> = ({
  image,
  name,
  type,
  price,
  id,
  onClick
}) => {
  const [imageUrl, setImageUrl] = useState(image);

  useEffect(() => {
    if (!image) {
      setImageUrl(
        "https://housing.com/news/wp-content/uploads/2023/01/a-4.jpg"
      );
      return;
    }
    setImageUrl(image);
  }, [image]);

  const handleImageError = () => {
    setImageUrl("https://housing.com/news/wp-content/uploads/2023/01/a-4.jpg");
  };

  return (
    <SubCategoryCardContainer onClick={onClick}>
      <Box className="sub_category_Box">
      <img
        src={imageUrl}
        onError={handleImageError}
        className="sub-category-image"
        alt="product card"
      />
      </Box>
      <Box className="sub-category-name-container">
        <Typography className="sub-category-name" title={name}>
          {name}
        </Typography>
        <Typography className="sub-category-description" title={type}>
          {type}
        </Typography>
        <Typography className="sub-category-price" title={String(price)}>
          GBP {price}
        </Typography>
      </Box>
    </SubCategoryCardContainer>
  );
};

const SubCategoryCardContainer = styled(Box)(({ theme }) => ({
  borderBottom: "1.5px solid #C9C9C9",
  cursor: "pointer",
  display: "block",
  textDecoration: "unset",
  "&:hover": {
    textDecoration: "unset",
  },
  "& .sub_category_Box": {
    borderRadius: "8px",
    width: "100%",
    height: "300px",
    objectFit: "contain",
    "@media (max-width: 600px)": {
      height: "260px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
  },
  "& .sub-category-image": {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    "@media (max-width: 600px)": {
      height: "260px",
      width: "60%",
    },
    "@media (max-width: 550px)": {
      width: "70%",
    },
    "@media (max-width: 500px)": {
      width: "80%",
    },
    "@media (max-width: 450px)": {
      width: "90%",
    },
    "@media (max-width: 400px)": {
      width: "100%",
    },
  },
  "& .sub-category-name-container": {
    alignItems: "center",
    textAlign: "center",
    marginTop: "16px",
    display: "flex",
    flexDirection: "column",
    marginLeft: '16px'
  },
  "& .sub-category-name": {
    color: "#252729",
    fontFamily: "Raleway",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "normal",
    textTransform: "capitalize",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
  },
  "& .sub-category-description": {
    color: "#000",
    fontFamily: "kawoszeh",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    textTransform: "capitalize",
    display: "-webkit-box",
    "-webkit-line-clamp": "2",
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "fit-content",
  },
  "& .sub-category-price": {
    color: "#252729",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    marginTop: "8px",
    lineHeight: "normal",
    marginBottom: "30px",
    width: "fit-content",
  },
  [theme.breakpoints.down("md")]: {
    "& .sub_category_Box": {
      height: "280px",
    },
    "& .sub-category-name": {
      fontSize: "22px",
    },
    "& .sub-category-description": {
      fontSize: "16px",
    },
    "& .sub-category-price": {
      fontSize: "16px",
      marginBottom: "30px",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .sub-category-name": {
      fontSize: "22px",
    },
   
    "& .sub-category-description": {
      fontSize: "16px",
    },
    "& .sub-category-price": {
      fontSize: "16px",
      marginBottom: "30px",
    },
  },
  [theme.breakpoints.down("xs")]: {
    "& .sub-category-name": {
      fontSize: "22px",
    },
    "& .sub-category-description": {
      fontSize: "16px",
    },
    "& .sub-category-price": {
      fontSize: "16px",
      marginBottom: "10px",
    },
  },
}));

export default SubCategoryCard;

// Customizable Area End
